import * as React from 'react';
import CardComponent from '../../common/ui/CardComponent';
import { useHistory, useParams } from 'react-router';
import { Button } from 'reactstrap';
import PaymentForm from './Form';

export function NewPayment() {
  const history = useHistory();
  let { company_id } = useParams();

  function GoBack() {
    return (
      <Button onClick={() => history.goBack()} className="btn btn-sm">
        Back
      </Button>
    );
  }

  return (
    <CardComponent icon="building" header={`Add Payment`} right={<GoBack />}>
      <PaymentForm company_id={company_id} />
    </CardComponent>
  );
}
