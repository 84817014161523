import React, { useEffect } from 'react';

import './App.scss';
import 'rsuite/dist/styles/rsuite-default.css';

import { useDispatch, useSelector } from 'react-redux';
import {
  loadCountries,
  appLoaded,
  loadCities,
  loadBalances,
} from './app/appSlice';
import { loadMeta } from './features/auth/authSlice';
import AppLoader from './common/ui/AppLoader';
import Router from './features/router';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCountries());
    dispatch(loadCities());
    dispatch(loadMeta());
    dispatch(loadBalances());
  }, [dispatch]);

  const ready = useSelector(appLoaded);
  if (!ready) return <AppLoader />;
  return <Router />;
}

export default App;
