import { useFormikContext } from 'formik';
import { Input } from 'reactstrap';
import BaseProps from './BaseProps';

import React from 'react';
interface Props extends BaseProps {}

function EmailField(props: Props) {
  const context = useFormikContext<any>();

  const { values, handleBlur, touched, errors, handleChange } = context;
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    props.onChange && props.onChange(e.target.value, context);
  };
  return (
    <Input
      type="email"
      name={props.name}
      id={props.id}
      onChange={changeHandler}
      readOnly={props.readonly}
      disabled={props.disabled}
      onBlur={handleBlur}
      value={values[props.name]}
      valid={touched[props.name] && !errors[props.name]}
      invalid={touched[props.name] && !!errors[props.name]}
      placeholder={props.placeholder}
    />
  );
}

EmailField.defaultInitialValue = '';
export default EmailField;
