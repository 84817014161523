import React, { useState, useEffect } from 'react';
import CardComponent from '../../common/ui/CardComponent';
import { FormikHelpers } from 'formik';
import Company from '../../common/interfaces/company';
import { useDispatch, useSelector } from 'react-redux';
import { services } from './resource';
import { createOrUpdate } from './slice';
import FormikForm, {
  FormikFailureHandler,
  TextField,
  TextAreaField,
} from '../../common/FormikForm';
import { useHistory, useParams } from 'react-router';
import { Notification } from 'rsuite';
import InputPickerField from '../../common/FormikForm/InputPickerField';
import { citiesSelector } from '../../app/appSlice';

import * as Yup from 'yup';
import AppLoader from '../../common/ui/AppLoader';
import { Link } from 'react-router-dom';
import { AppDispatch } from 'app/store';
import { AxiosError } from 'axios';
function Update() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const cities = useSelector(citiesSelector);
  const [loadedCompany, setLoadedCompany] = useState<Company | null>(null);
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id) {
      services.load(id).then((response) => {
        setLoadedCompany(response.data);
      });
    }
  }, [id]);

  const onSubmit = (values: Company, helpers: FormikHelpers<Company>) => {
    dispatch(createOrUpdate(values, id))
      .then((data: Company) => {
        if (id)
          Notification.success({
            title: `Company ${data.name} modified`,
          });
        else
          Notification.success({
            title: `Company ${data.name} created!`,
          });

        history.push('/companies');
        helpers.setSubmitting(false);
      })
      .catch((e: AxiosError) => FormikFailureHandler(e, helpers));
  };
  if (id && !loadedCompany) return <AppLoader />;
  return (
    <CardComponent
      header={loadedCompany ? loadedCompany.name : 'New Company'}
      icon="building"
      right={
        <Link to="/companies" className="btn btn-warning btn-sm">
          Back
        </Link>
      }
    >
      <FormikForm onSubmit={onSubmit} initialValues={loadedCompany}>
        <TextField
          label="Name"
          name="name"
          validationSchema={Yup.string().required()}
        />
        <TextAreaField
          label="Address"
          name="address"
          validationSchema={Yup.string().required()}
        />
        <InputPickerField
          name="city_id"
          placeholder="Select City"
          label="City"
          validationSchema={Yup.number().required()}
          options={cities?.map((city) => ({
            label: city.name,
            value: city.id,
          }))}
        />
      </FormikForm>
    </CardComponent>
  );
}

export default Update;
