import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'rsuite';

import Transaction from '../../common/interfaces/transaction';
function TransactionsTable(props: { transactions: Transaction[] }) {
  const { transactions } = props;

  return (
    <Table className="table" bordered hover data={transactions}>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.Cell dataKey="id" />
      </Table.Column>
      <Table.Column flexGrow={2}>
        <Table.HeaderCell>Entity Type</Table.HeaderCell>
        <Table.Cell>
          {(row: Transaction) => {
            let slug = '';
            switch (row.entity_type) {
              case 'Payment':
                slug = 'payments/';
                break;
              case 'Payout':
                slug = 'payouts/';
                break;
            }
            return (
              <Link target="_" to={`/${slug}${row.entity_id}`}>
                {row.entity_type}
              </Link>
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Amount</Table.HeaderCell>
        <Table.Cell dataKey="amount" />
      </Table.Column>
    </Table>
  );
}

export default TransactionsTable;
