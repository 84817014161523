import React from 'react';
import { Table } from 'rsuite';
import { Link } from 'react-router-dom';
import Company from '../../common/interfaces/company';
function CompanyTable(props: { list: Company[] }) {
  const { list } = props;
  return (
    <div>
      <Table loading={!list} autoHeight={true} data={list}>
        <Table.Column width={100}>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column width={300} resizable={true} fixed={'left'}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Company) => (
              <Link to={`/companies/${rowData.id}`}>{rowData.name}</Link>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>City</Table.HeaderCell>
          <Table.Cell>{(rowData: Company) => rowData.city!.name}</Table.Cell>
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Active Contracts</Table.HeaderCell>
          <Table.Cell dataKey="contracts_count" />
        </Table.Column>
      </Table>
    </div>
  );
}

export default CompanyTable;
