import { useFormikContext } from "formik";
import BaseProps from "./BaseProps";
import React from "react";
import { DatePicker, DatePickerProps } from "rsuite";
interface Props extends BaseProps {
  datepickerProps?: DatePickerProps;
}

function DateField(props: Props) {
  const context = useFormikContext<any>();
  const { values, handleBlur, setFieldValue } = context;
  const { onChange, id, ...rest } = props;
  const changeHandler = (value: Date) => {
    setFieldValue(props.name, value);
    props.onChange && props.onChange(value, context);
  };
  return (
    <DatePicker
      block={true}
      value={values[props.name]}
      id={props.id}
      onBlur={handleBlur}
      onChange={changeHandler}
      disabled={props.disabled}
      readonly={props.readonly}
      {...props.datepickerProps}
      {...rest}
    />
  );
}

export default DateField;
