import React from 'react';
import { Link } from 'react-router-dom';

import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Container,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
  Input,
  Media,
} from 'reactstrap';
import { userSelector, logout } from '../features/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Notification } from 'rsuite';

function Header() {
  const dispatch = useDispatch();
  const doLogout = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(logout());
    Notification.success({ title: 'Logged Out!' });
  };
  const user = useSelector(userSelector);

  return (
    <>
      <Navbar
        className="navbar-top"
        color="primary"
        expand="md"
        id="navbar-main"
      >
        <Container fluid={true}>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            Sportfix
          </Link>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav={true} inNavbar={true}>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  {
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={`https://place-hold.it/300`} />
                    </span>
                  }
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm text-white font-weight-bold">
                      {user!.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to="/account" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My Account</span>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem onClick={doLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
export default Header;
