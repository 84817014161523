import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { userSelector } from '../auth/authSlice';

function AuthenticatedRoute(props: {
  component?: any;

  children?: any;
  path: string;
  exact?: boolean;
}) {
  const { component, ...otherProps } = props;
  const user = useSelector(userSelector);
  if (user) {
    return (
      <div>
        <Route {...otherProps} component={component}>
          {props.children}
        </Route>
      </div>
    );
  } else {
    return <Redirect to="/login" />;
  }
}
export default AuthenticatedRoute;
