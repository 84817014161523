import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Sidebar from '../../app/Sidebar';
import Header from '../../app/Header';
import Dashboard from '../../app/Dashboard';
import Login from '../auth/Login';
import ForgotPassword from '../auth/ForgotPassword';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import ForgotPasswordChange from '../auth/ForgotPasswordChange';
import MyAccount from '../auth/MyAccount';
import ContractorRouter from '../contractors';
import CompanyRouter from '../companies';
import PaymentRouter from '../payments';
import ErrorHandler from '../../app/ErrorHandler';
import PayoutRouter from '../payouts';
import ContractRouter from '../contracts';

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <UnauthenticatedRoute exact path="/login" component={Login} />
        <UnauthenticatedRoute exact path="/forgot" component={ForgotPassword} />
        <UnauthenticatedRoute
          exact
          path="/forgot_change"
          component={ForgotPasswordChange}
        />

        <AuthenticatedRoute path="/">
          <Header />
          <Sidebar />
          <div className="main-content mt-4 p-3">
            {<ErrorHandler />}
            <Switch>
              <AuthenticatedRoute path="/" component={Dashboard} />

              <AuthenticatedRoute
                path="/contractors"
                component={ContractorRouter}
              />
              <AuthenticatedRoute
                path="/contracts"
                component={ContractRouter}
              />
              <AuthenticatedRoute path="/companies" component={CompanyRouter} />
              <AuthenticatedRoute path="/payments" component={PaymentRouter} />
              <AuthenticatedRoute path="/payouts" component={PayoutRouter} />
              <AuthenticatedRoute path="/account" exact component={MyAccount} />
            </Switch>
          </div>
        </AuthenticatedRoute>
        <Route>
          <h2>404: Not found</h2>;
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
