import Resource, { generateServices } from '../../common/Resource';
import Company from '../../common/interfaces/company';

import axios from '../../common/axios';
const resource = new Resource({
  name: 'company',
  memberUrlPattern: '/companies',
  collectionUrlPattern: '/companies',
  parents: [],
});

export type FilterParams = { city_id_eq: string };
const services = {
  ...generateServices<Company, FilterParams>(resource, axios),
};
export { resource, services };
