import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Row, Col, Panel, Whisper, Popover, Icon, Drawer } from 'rsuite';
import moment from 'moment';

import { services } from './resource';
import { actions, selectors, retryPayout } from './slice';
import AppLoader from 'common/ui/AppLoader';
import CardComponent from 'common/ui/CardComponent';
import PayoutContractsTable from './PayoutContractsTable';
import { Button, Alert } from 'reactstrap';

const formatedDate = (date: string) => {
  return moment(date).format('DD MMMM YYYY');
};

function PayoutShow() {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  React.useEffect(() => {
    services.load(id).then((response) => {
      dispatch(actions.loadedOne(response.data));
    });
  }, [dispatch, id]);

  const payout = useSelector(selectors.storeSelector)[id];

  const handleRetry = (payout_id: number) => {
    dispatch(retryPayout(payout_id));
  };

  const [errorDrawerOpen, setErrorDrawerOpen] = useState(false);
  if (!payout) return <AppLoader></AppLoader>;

  return (
    <CardComponent
      icon="money-bill"
      header={`Payout #${id}`}
      right={
        <>
          <i className="fa fa-building p-1" />
          Contractor
        </>
      }
    >
      {payout.error && (
        <>
          <Alert fade={false} color="danger">
            Error: {payout.error}
            <Button
              size="sm"
              className="ml-3"
              onClick={setErrorDrawerOpen.bind({}, true)}
            >
              Show Error
            </Button>
          </Alert>
          <Drawer
            show={errorDrawerOpen}
            onHide={setErrorDrawerOpen.bind({}, false)}
          >
            <Drawer.Header>
              <Drawer.Title>Error</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <p>
                <strong>Message: </strong>
                {payout.error}
              </p>
              <p>
                <strong>Date & Time: </strong>
                {payout.errored_at}
              </p>
              {payout.backtrace && (
                <p className="">
                  <p>
                    <strong>Backtrace</strong>
                  </p>
                  {payout.backtrace?.split('\n').map((line: string) => (
                    <div className="border-bottom">{line}</div>
                  ))}
                </p>
              )}
            </Drawer.Body>
          </Drawer>
        </>
      )}
      <Grid fluid>
        <Row gutter={25} style={{ marginBottom: 30 }}>
          <Col xs={12} sm={3} md={6}>
            <Panel shaded className="text-center">
              <h5>
                <i className="fa fa-dollar-sign p-1" />
                {payout.amount}
              </h5>
            </Panel>
          </Col>
          <Col xs={12} sm={3} md={6}>
            <Panel shaded className="text-center">
              <h5>
                <i className="fa fa-calendar-alt p-1" />
                {formatedDate(payout.date)}
              </h5>
            </Panel>
          </Col>
          <Col xs={12} sm={3} md={6}>
            <Panel shaded className="text-center">
              <h5 className="d-flex justify-content-center">
                <i className="fa fa-signal p-1" />
                {payout.status}

                {(payout.status === 'failed' ||
                  payout.status === 'canceled') && (
                  <div>
                    <Whisper
                      trigger="hover"
                      speaker={
                        <Popover title="Error">
                          <p>{payout.error}</p>
                        </Popover>
                      }
                    >
                      <span style={{ marginLeft: 5 }}>
                        <Icon icon="info" style={{ color: 'red' }} />
                      </span>
                    </Whisper>
                    <Button
                      color="outline-success"
                      className="ml-2 btn-sm mt-0 py-0"
                      onClick={() => handleRetry(payout.id)}
                    >
                      <i className="fa fa-redo-alt" />
                    </Button>
                  </div>
                )}
              </h5>
            </Panel>
          </Col>
          <Col xs={12} sm={3} md={6}>
            <Panel shaded className="text-center">
              <h5>
                <i className="fa fa-list p-1" />
                {payout.child_counts.failed}/{payout.child_counts.total} Failed
              </h5>
            </Panel>
          </Col>
        </Row>
        <Row style={{ marginBottom: 30 }}>
          <PayoutContractsTable payoutsContracts={payout.payout_contracts} />
        </Row>
      </Grid>
    </CardComponent>
  );
}

export default PayoutShow;
