import { useFormikContext } from "formik";
import InputFieldBaseProps from "./BaseProps";
import React, { useState } from "react";
import { Modal, Button } from "rsuite";
import ContractorList from "../../features/contractors/ContractorList";
import IContractor from "../interfaces/contractor";

function Contractor(props: IContractor) {
  return (
    <div className={"d-block w-100"}>
      <span className="fa fa-edit float-right" />
      <p className={"p-1"}>{`${props.first_name} ${props.last_name}`}</p>
      <p className={"p-1"}>{props.email}</p>
      <p className={"p-1"}>{props.country?.name}</p>
    </div>
  );
}

function ContractorSelectField(props: InputFieldBaseProps) {
  const context = useFormikContext<any>();
  const { setFieldValue } = context;

  const [chosen, setChosen] = useState<IContractor>();

  const changeHandler = (rowData: any) => {
    setFieldValue(props.name, rowData.id);
    setChosen(rowData);
    if (props.onChange) props.onChange(rowData.id, context);
  };

  const [show, setShow] = React.useState(false);
  const open = () => setShow(true);
  const ok = () => setShow(false);
  const cancel = () => {
    setChosen((prev) => undefined);
    setShow(false);
  };

  const contractor = chosen;

  return (
    <div className="modal-container">
      <Button size="lg" onClick={open} className={"contractorBtn"}>
        {contractor ? (
          <Contractor {...contractor} />
        ) : (
          <>
            <span> Select Contractor </span>
            <span className="fa fa-search" />
          </>
        )}
      </Button>
      <Modal full={true} show={show} onHide={cancel}>
        <Modal.Body>
          <ContractorList chosen={chosen} handleSelect={changeHandler} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ok} appearance="primary">
            Ok
          </Button>
          <Button onClick={cancel} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ContractorSelectField;
