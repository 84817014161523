import React from 'react';
import { Switch } from 'react-router';
import AuthenticatedRoute from '../router/AuthenticatedRoute';
import PayoutList from './PayoutList';
import PayoutShow from './PayoutShow';
import UpcomingPayouts from './Upcoming';

function PayoutRouter() {
  return (
    <Switch>
      <AuthenticatedRoute path="/payouts" exact component={PayoutList} />
      <AuthenticatedRoute path="/payouts/upcoming" exact component={UpcomingPayouts} />
      <AuthenticatedRoute path="/payouts/:id" exact component={PayoutShow} />
    </Switch>
  );
}

export default PayoutRouter;
