import React from 'react';
import { Switch } from 'react-router';
import AuthenticatedRoute from '../router/AuthenticatedRoute';
import ContractorList from './ContractorList';
import ContractorForm from './Form';
import ContractorShow from './Show';

function ContractorRouter() {
  return (
    <Switch>
      <AuthenticatedRoute
        path="/contractors"
        exact
        component={ContractorList}
      />
      <AuthenticatedRoute
        path="/contractors/new"
        exact
        component={ContractorForm}
      />
      <AuthenticatedRoute path="/contractors/:id/edit" exact component={ContractorForm} />
      <AuthenticatedRoute path="/contractors/:id" exact component={ContractorShow} />
    </Switch>
  );
}

export default ContractorRouter;
