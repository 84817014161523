import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import User from '../../common/interfaces/user';
import * as services from './services';
import LoginResponse from '../../common/interfaces/loginResponse';
import LoginRequest from '../../common/interfaces/loginRequest';

interface AuthState {
  user?: User;
  token?: string;
  metaLoaded: boolean;
}

const initialState: AuthState = { metaLoaded: true };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedIn: (state, { payload }: PayloadAction<LoginResponse>) => {
      state.user = payload.user;
      state.token = payload.token;
    },

    metaLoaded: (state) => {
      state.metaLoaded = true;
    },
    logout: (state) => {
      state.user = undefined;
      state.token = undefined;
      localStorage.removeItem('auth_token');
    },
  },
});

export const { loggedIn, metaLoaded, logout } = authSlice.actions;
export const signup = (userDetails: User): AppThunk<Promise<any>> => (
  dispatch
) => {
  return services.signup(userDetails);
};

export const login = (
  loginRequest: LoginRequest
): AppThunk<Promise<LoginResponse>> => (dispatch) => {
  return services.login(loginRequest).then((response: LoginResponse) => {
    dispatch(loggedIn(response));
    return response;
  });
};

export const loadMeta = (): AppThunk<Promise<LoginResponse | void>> => (
  dispatch
) => {
  return services
    .meta()
    .then((response: LoginResponse) => {
      dispatch(loggedIn(response));
      dispatch(metaLoaded());
      return response;
    })
    .catch(() => {
      dispatch(metaLoaded());
    });
};

export const forgotPassword = (email: string): AppThunk<Promise<any>> => (
  dispatch
) => {
  return services.forgotPassword(email).then((data: any) => {
    return data;
  });
};

export const updateAccount = (user: User): AppThunk<Promise<any>> => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    services
      .updateAccount(user)
      .then(() => {
        dispatch(loadMeta()).then((data) => resolve(data));
      })
      .catch(reject);
  });
};

export const userSelector = (state: RootState) => {
  return state.auth.user;
};

export default authSlice.reducer;
