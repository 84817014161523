import { useFormikContext } from 'formik';
import BaseProps from './BaseProps';

import React, { ReactElement } from 'react';
import { InputPicker } from 'rsuite';
interface Props extends BaseProps {
  options?: { label: string | ReactElement; value: any }[];
  placeholder: string;
}

function InputPickerField(props: Props) {
  const context = useFormikContext<any>();

  const { values, handleBlur, touched, errors } = context;
  const changeHandler = (value: string) => {
    context.setFieldValue(props.name, value, true);
    props.onChange && props.onChange(value, context);
  };
  return (
    <InputPicker
      block
      name={props.name}
      onChange={changeHandler}
      data={props.options || []}
      placeholder={props.placeholder}
      readOnly={props.readonly}
      disabled={props.disabled}
      onBlur={handleBlur}
      value={values[props.name]}
      valid={touched[props.name] && !errors[props.name]}
      invalid={touched[props.name] && !!errors[props.name]}
    />
  );
}

export default InputPickerField;
