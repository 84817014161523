import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Container, Card, CardBody } from 'reactstrap';
import { verify_forgot_token, forgot_password_change } from './services';
import { AxiosError } from 'axios';
import FormikForm, {
  FormikFailureHandler,
  PasswordField,
} from '../../common/FormikForm';
import { FormikHelpers } from 'formik';
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import AuthLinks from './AuthLinks';

import * as Yup from 'yup';
export default function ForgotPasswordChange() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let email = searchParams.get('email');
  if (email) email = atob(email);
  const token = searchParams.get('token');
  const [error, setError] = useState<string | null>(null);
  const [completed, setCompleted] = useState<boolean>(false);
  const [tokenValid, setTokenValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!token || !email) {
      setLoading(false);

      setError('missing_date');
    } else {
      console.log(email, token);
      verify_forgot_token(email, token)
        .then(() => {
          setError(null);
          setTokenValid(true);
          setLoading(false);
        })
        .catch((err: AxiosError) => {
          setLoading(false);
          if (err.response) {
            if (err.response.status === 401) {
              setError('invalid_token');
            }
          }
        });
    }
  }, [email, token]);

  const onSubmit = (
    values: { password: string; confirm_password: string },
    actions: FormikHelpers<{ password: string; confirm_password: string }>
  ) => {
    forgot_password_change(email!, token!, values.password)
      .then(() => {
        setCompleted(true);
        actions.setSubmitting(false);
      })
      .catch((e) => {
        FormikFailureHandler(e, actions);
        actions.setSubmitting(false);
      });
  };
  const renderCompleted = () => {
    return (
      <Fade>
        <div className="p-3">
          <h2 className="text-center mb-3">
            <i className="fa fa-check text-success fa-4x" />
          </h2>
          Your password has been changed. You can now{' '}
          <Link to={`/login?email=${email}`}> Login</Link>
        </div>
      </Fade>
    );
  };
  const renderForm = () => {
    return (
      <Fade>
        <FormikForm<any> onSubmit={onSubmit} submitLabel="Change">
          <PasswordField
            name="password"
            label="Password"
            validationSchema={Yup.string().required('is required')}
          />

          <PasswordField
            name="password_confirmation"
            label="Password"
            validationSchema={Yup.string()
              .required('is required')
              .oneOf([Yup.ref('password')], 'must match password')}
          />
        </FormikForm>
      </Fade>
    );
  };
  const renderInvalid = () => {
    return (
      <Fade>
        <h1 className="text-center">
          <i className="fa fa-exclamation-circle" />
        </h1>
        <h2 className="text-center">Error</h2>

        <div className=" mt-5  d-flex justify-content-center">
          <div className=" border text-center  p-5">
            {error === 'invalid_token' && (
              <div>
                Token was invalid or expired. You can resend the forgot password
                email by clicking below again by clicking below
                <div className="mt-3"></div>
                <Link to={`/forgot?email=${email}`} className="btn btn-primary">
                  Forgot password
                </Link>
              </div>
            )}

            {error === 'data_missing' && (
              <div>
                You're missing some data required to complete the process. Try
                copying the link from your email and pasting it in the browser.
                <br />
              </div>
            )}
          </div>
        </div>
      </Fade>
    );
  };
  const renderLoading = () => {
    return (
      <>
        <h1 className="text-center">
          <i className="fa fa-spin fa-spinner" />
        </h1>
        <h4 className="text-center">
          Please wait while we verify your details{' '}
        </h4>
      </>
    );
  };

  return (
    <div className="main-content bg-default">
      <div className="header bg-gradient-primary py-7 py-lg-8">
        <Container>
          <div className="header-body text-center mb-7">
            <h1 className="text-white">Change Password</h1>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
      {/* Page content */}

      <Container className="mt--8 pb-5 w-25">
        <Card className="bg-secondary shadow border-0 ">
          <CardBody>
            {loading && renderLoading()}
            {!loading && !completed && (
              <div>
                {error && renderInvalid()}
                {tokenValid && !error && renderForm()}
              </div>
            )}

            {completed && renderCompleted()}
            <AuthLinks email={email} />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
