import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { AxiosError } from 'axios';
import FormikForm, {
  FormikFailureHandler,
  TextField,
  DateField,
} from 'common/FormikForm';
import { countriesSelector } from 'app/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import CardComponent from 'common/ui/CardComponent';
import { Link } from 'react-router-dom';
import Contractor from 'common/interfaces/contractor';
import { services } from './resource';
import { services as servicesCompany } from 'features/companies/resource';
import { createOrUpdate } from './slice';
import { useHistory, useParams } from 'react-router';
import { FormikHelpers } from 'formik';
import { Notification } from 'rsuite';
import InputPickerField from 'common/FormikForm/InputPickerField';
import Company from 'common/interfaces/company';
import { AppDispatch } from 'app/store';

function ContractorForm(props: { onSubmit: any; email?: string }) {
  const countries = useSelector(countriesSelector);
  const [loadedContractor, setLoadedContractor] = useState<Contractor | null>(
    null
  );

  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { id } = params;
  const countryOptions = countries
    ? [
        ...countries.map((country) => {
          return { value: country.id, label: country.name };
        }),
      ]
    : [];
  const [, setCompanies] = useState<Company[] | null>(null);

  useEffect(() => {
    if (id) {
      services.load(id).then((response) => {
        setLoadedContractor(response.data);
      });
    }
    servicesCompany
      .loadAll({ query: '', filters: {}, page: 1 })
      .then((resp) => {
        setCompanies(resp.data);
      });
  }, [id]);

  const onSubmit = (values: Contractor, helpers: FormikHelpers<Contractor>) => {
    dispatch(createOrUpdate(values, id))
      .then((data: Contractor) => {
        if (id)
          Notification.success({
            title: `Contractor ${data.first_name} modified`,
          });
        else
          Notification.success({
            title: `Contractor ${data.first_name} created!`,
          });

        history.push('/contractors');
        helpers.setSubmitting(false);
      })
      .catch((e: AxiosError) => FormikFailureHandler(e, helpers));
  };
  return (
    <CardComponent
      header={loadedContractor ? loadedContractor.first_name : 'New Contract'}
      icon="building"
      right={
        <Link to="/contractors" className="btn btn-warning btn-sm">
          Back
        </Link>
      }
    >
      <FormikForm onSubmit={onSubmit} initialValues={loadedContractor}>
        <TextField
          label="First Name"
          name="first_name"
          validationSchema={Yup.string().required()}
          placeholder="John"
        />
        <TextField
          label="Last Name"
          name="last_name"
          validationSchema={Yup.string().required()}
          placeholder="Doe"
        />
        <TextField
          label="Email Address"
          name="email"
          validationSchema={Yup.string().email().required()}
          placeholder="john.doe@gmail.com"
        />
        <TextField
          label="Phone Number"
          name="phone"
          validationSchema={Yup.string().required()}
          placeholder="+X-XXX-XXXXXXX"
        />
        <DateField
          label="Date of Birth"
          name="dob"
          validationSchema={Yup.string().required()}
          initialValue={moment().add('-18', 'years').toDate()}
        />
        <TextField
          label="Passport Number"
          name="passport_number"
          validationSchema={Yup.string().required()}
          placeholder="Z213333Q"
        />
        <InputPickerField
          name="birth_country_id"
          placeholder="Select Country of Birth"
          label="Country of Birth"
          validationSchema={Yup.number().required('is required')}
          options={countryOptions}
        />
        <h3 className="pb-0">Residential Details</h3>
        <hr />
        <TextField
          label="Address Street 1"
          name="address_street1"
          validationSchema={Yup.string().required('is required')}
        />
        <TextField
          label="Address Street 2"
          name="address_street2"
          validationSchema={Yup.string().required('is required')}
        />
        <TextField
          label="City"
          name="city"
          validationSchema={Yup.string().required('is required')}
        />
        <TextField label="Province" name="province" />
        <TextField
          label="Postal Code"
          name="postal_code"
          validationSchema={Yup.string().required()}
        />
        <InputPickerField
          name="country_id"
          placeholder="Select Country"
          label="Country"
          options={countryOptions}
          validationSchema={Yup.number().required(' is required')}
        />
        <TextField label="Identification Number" name="identification_number" />

      </FormikForm>
    </CardComponent>
  );
}

export default ContractorForm;
