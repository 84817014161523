import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  loadUpcomingPayouts,
  upcomingPayoutsSelector,
  createPayout,
} from './slice';
import UpcomingPayout from 'common/interfaces/upcoming_payout';
import { Button } from 'reactstrap';
import CardComponent from 'common/ui/CardComponent';
import { Table, Notification, Loader } from 'rsuite';
import PayoutContract from 'common/interfaces/payout_contract';
import { Link } from 'react-router-dom';
import { AppDispatch } from 'app/store';
const { Cell, HeaderCell, Column } = Table;

function UpcomingPayouts() {
  const dispatch: AppDispatch = useDispatch();
  const upcomingPayouts: UpcomingPayout[] = useSelector(
    upcomingPayoutsSelector
  );

  const [creatingPayout, setCreatingPayout] = useState(false);
  const handleCreatePayout = (date: string, company_id: number) => {
    setCreatingPayout(true);
    dispatch(createPayout(date, company_id))
      .then(() => {
        Notification.success({ title: 'Payout created!' });
      })
      .catch(() => {
        Notification.error({ title: 'Could not create payout' });
      })
      .then(() => {
        setCreatingPayout(false);
      });
  };
  React.useEffect(() => {
    if (_.isEmpty(upcomingPayouts)) dispatch(loadUpcomingPayouts());
  }, [dispatch, upcomingPayouts]);

  return (
    <div>
      {_.isArray(upcomingPayouts) &&
        upcomingPayouts.map((upcomingPayout: UpcomingPayout) => (
          <CardComponent
            collapsible={true}
            icon={'calendar'}
            right={
              <>
                <h4 className="pr-2 pl-2 bg-blue text-white">
                  {upcomingPayout.payouts.reduce((acc, p) => {
                    return acc + (p.amount || 0);
                  }, 0)}
                  $
                </h4>
              </>
            }
            header={upcomingPayout.date.toString()}
          >
            {upcomingPayout.payouts.map((payout) => (
              <CardComponent
                icon={'fa-building'}
                header={
                  <>
                    {payout.company.name}{' '}
                    <span className="pr-2 pl-2 bg-yellow text-black">
                      {payout.company.balance || 0} $
                    </span>{' '}
                  </>
                }
                right={
                  <>
                    <h4 className="pr-2 pl-2 bg-blue text-white">
                      {payout.amount || 0} $
                    </h4>
                  </>
                }
                collapsible={true}
                isCollapsed={false}
              >
                <Table data={payout.payout_contracts}>
                  <Column flexGrow={2}>
                    <HeaderCell>Contractor</HeaderCell>
                    <Cell>
                      {(rowData: PayoutContract) => (
                        <Link
                          to={`/contractors/${rowData.contract?.contractor?.id}`}
                        >
                          {rowData.contract?.contractor?.first_name! +
                            ' ' +
                            rowData.contract?.contractor?.last_name!}
                        </Link>
                      )}
                    </Cell>
                  </Column>

                  <Column flexGrow={1}>
                    <HeaderCell>Frequency</HeaderCell>
                    <Cell>
                      {(rowData: PayoutContract) => rowData.contract?.frequency}
                    </Cell>
                  </Column>
                  <Column align="right" flexGrow={1}>
                    <HeaderCell>Amount</HeaderCell>
                    <Cell dataKey={'amount'} />
                  </Column>
                </Table>
                <Button
                  color="primary"
                  onClick={handleCreatePayout.bind(
                    {},
                    payout.date,
                    payout.company_id
                  )}
                  disabled={!!payout.id || creatingPayout}
                >
                  Create Payout
                  {creatingPayout && <Loader />}
                </Button>
              </CardComponent>
            ))}
          </CardComponent>
        ))}
    </div>
  );
}

export default UpcomingPayouts;
