
import React, { useState } from 'react';
import ContractorAccount from 'common/interfaces/contractor_account';
import Contractor from 'common/interfaces/contractor';
import { Table } from 'rsuite';
import { Badge, Button } from 'reactstrap'
import { Notification } from 'rsuite';
import { services } from './resource';
import { useDispatch } from 'react-redux';
import { actions } from './slice';

function AccountsTable(props: { contractor: Contractor }) {
  const [primaryInProcess, setPrimaryInProcess] = useState<null | number>(null)
  const [deletinginProcess, setDeletinginProcess] = useState<null | number>(null)
  let list: ContractorAccount[] = props.contractor.contractor_accounts!
  const { contractor } = props
  const dispatch = useDispatch();
  const setPrimary = (contractor_account_id: number) => {
    setPrimaryInProcess(contractor_account_id)
    services.makeAccountPrimary(contractor_account_id)
      .then((response) => {
        dispatch(actions.madePrimary({
          prev_id: contractor_account_id,
          contractor_id: contractor.id,
          data: response
        }))
        Notification.success({ title: 'Primary Set' });
      })
      .catch((error: any) => {
        Notification.error({ title: 'Something went wrong while setting primary' });
      })
      .then(() => {
        setPrimaryInProcess(null)
      })
  }
  const deleteAccount = (contractor_account_id: number) => {
    setDeletinginProcess(contractor_account_id)
    services.deleteAccount(contractor_account_id)
      .then((response) => {
        dispatch(actions.deletedAccount({
          prev_id: contractor_account_id,
          contractor_id: contractor.id,
          data: response
        }))
        Notification.success({ title: 'Account deleted' });
      })
      .catch((error: any) => {
        Notification.error({ title: 'Something went wrong while deleting account' });
      })
      .then(() => {
        setDeletinginProcess(null)
      })
  }
  if (list.length === 0) return <div className="text-center mt-1">No Acccounts Yet</div>
  return (
    <Table loading={!list} data={list} autoHeight height={420} headerHeight={0} affixHorizontalScrollbar>
      <Table.Column width={50} verticalAlign="middle" fixed>
        <Table.HeaderCell />
        <Table.Cell>
          {
            (rowData: ContractorAccount) => {
              switch (rowData.account_type) {
                case 'paypal': return (<i className="fab fa-paypal p-1" />)
                case 'bank-transfer': return (<i className="fa fa-university p-1" />)
              }
            }
          }
        </Table.Cell>
      </Table.Column>
      <Table.Column minWidth={150} flexGrow={2} verticalAlign="middle">
        <Table.HeaderCell />
        <Table.Cell>
          {
            (rowData: ContractorAccount) => {
              switch(rowData.account_type) {
                case 'paypal': return (<>{ rowData.paypal_email }</>)
                case 'bank-transfer': return (<>{rowData.bank_name} {rowData.account_num}</>)
              }
            }
          }
        </Table.Cell>
      </Table.Column>
      <Table.Column minWidth={200} flexGrow={1} align='center' verticalAlign="middle">
        <Table.HeaderCell />
        <Table.Cell className="d-flex justify-content-between flex-wrap">
          {(rowData: ContractorAccount) => {
              if (rowData.primary) {
                return (<Badge color="primary">
                  <i className="fa fa-check " /> Primary
                </Badge>)
              }
              else {
                return (<>
                  <Button
                    size="sm"
                    color="success"
                    disabled={primaryInProcess === rowData.id!}
                    onClick={setPrimary.bind({}, rowData.id!)}
                  >
                    Set Primary
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    disabled={deletinginProcess === rowData.id!}
                    onClick={deleteAccount.bind({}, rowData.id!)}
                  >
                    Delete
                  </Button>
                </>)
              }
            }
          }
        </Table.Cell>
      </Table.Column>
    </Table>
  )
}
export default AccountsTable;
