import { useFormikContext } from 'formik';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import BaseProps from './BaseProps';

import React from 'react';
interface Props extends BaseProps {}

function AmountField(props: Props) {
  const context = useFormikContext<any>();

  const { values, handleBlur, touched, errors, handleChange } = context;
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    props.onChange && props.onChange(e.target.value, context);
  };
  return (
    <InputGroup>
     <InputGroupAddon addonType="prepend">$</InputGroupAddon>
      <Input
        type="number"
        name={props.name}
        id={props.id}
        onChange={changeHandler}
        readOnly={props.readonly}
        disabled={props.disabled}
        onBlur={handleBlur}
        value={values[props.name]}
        valid={touched[props.name] && !errors[props.name]}
        invalid={touched[props.name] && !!errors[props.name]}
        placeholder={props.placeholder}
      />
   </InputGroup>
  );
}

AmountField.defaultInitialValue = '';
export default AmountField;
