import React, { useState, useEffect } from 'react';
import { InputPicker, Loader } from 'rsuite';
import Company from '../../common/interfaces/company';
import { services } from './resource';
import { useDebouncedCallback } from 'use-debounce';

function CompanySearchBox(props: {
  onChange?: (company_id: string) => void;
  value: string;
}) {
  const [companies, setCompanies] = useState<Company[] | null>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { value } = props;

  const companyOptions = companies?.map((c) => ({
    label: c.name,
    value: c.id,
  }));
  const doSearch = () => {
    setLoading(true);
    services
      .loadAll({ query: searchString, filters: {}, page: 1 })
      .then((resp) => {
        setCompanies(resp.data);
        setLoading(false);
      });
  };
  const [doSearchThrottled] = useDebouncedCallback(
    // function
    () => {
      doSearch();
    },
    // delay in ms
    500
  );

  useEffect(() => {
    if (searchString.length >= 3) {
      doSearchThrottled();
    }
  }, [searchString, doSearchThrottled]);
  useEffect(() => {
    if (value) {
      setLoading(true);

      services.load(value).then((resp) => {
        setCompanies([resp.data]);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSearch = (val: string) => {
    setSearchString(val);
    setCompanies(null);
  };
  return (
    <InputPicker
      placeholder="Select Company"
      onSearch={onSearch}
      value={props.value}
      onChange={props.onChange}
      data={companyOptions || []}
      renderMenu={(menu) => {
        if (searchString.length < 3) {
          return <p className="p-2">Type 3 characters to search</p>;
        }
        if (searchString.length >= 3 && !companies && !loading) {
          return <p className="p-2">Stop typing to search</p>;
        }
        if (loading)
          return (
            <p className="p-2">
              <Loader className="p-1"></Loader>
              Searching for {searchString}
            </p>
          );
        return menu;
      }}
    ></InputPicker>
  );
}

export default CompanySearchBox;
