import { createSlice } from '@reduxjs/toolkit';
import { services, FilterParams } from './resource';
import { AppThunk } from '../../app/store';
import {
  ResourceInitialState,
  createResourceAdapter,
} from '../../common/Resource';
import Payment from '../../common/interfaces/payment';
import { AxiosResponse } from 'axios';

interface State extends ResourceInitialState<Payment, FilterParams> {}

const adapter = createResourceAdapter<Payment, FilterParams>('payment');

const slice = createSlice({
  name: 'payment',
  initialState: { ...adapter.initialState } as State,
  reducers: { ...adapter.reducers },
});

const { loadedMany } = slice.actions;

export const { actions } = slice;
export const { selectors } = adapter;

export const loadMany = (
  page: number = 1,
  query = '',
  filters: Partial<FilterParams>
): AppThunk<any> => (dispatch) => {
  return services.loadAll({ query, filters, page }).then((response) => {
    dispatch(
      loadedMany({
        data: response.data,
        filters,
        query,
        page: parseInt(response.headers['pagination-current-page'], 10),
        totalPages: parseInt(response.headers['pagination-total-pages'], 10),
      })
    );
  });
};

export const createOrUpdate = (payload: Payment): AppThunk<Promise<any>> => (
  dispatch
) => {
  const prepareData = (payment: Payment) => {
    const formData = new FormData();
    for (const key of Object.getOwnPropertyNames(payment)) {
      // @ts-ignore
      const item: any = payment[key];
      formData.append(`payment[${key}]`, item);
    }
    return formData;
  };
  let data = prepareData(payload);
  return services.create(data, {}).then((response: AxiosResponse<Payment>) => {
    dispatch(actions.created(response.data));
    return response;
  });
};

export default slice.reducer;
