import RequiredParentMissingError from './RequiredParentMissingError';
import { AxiosInstance, AxiosResponse } from 'axios';
import { PayloadAction, createSelector } from '@reduxjs/toolkit';
export interface IResource {
  name: string;
  parents: string[];
  collectionUrlPattern: string;
  memberUrlPattern: string;
}

/*
 Defines a resource.
 Handles creating appropraite endpoint url for a particular resource.
 It handles
*/
export default class Resource implements IResource {
  name: string;
  parents: string[];

  collectionUrlPattern: string;
  memberUrlPattern: string;

  constructor(arg: IResource) {
    this.name = arg.name;
    this.parents = arg.parents;
    this.collectionUrlPattern = arg.collectionUrlPattern;
    this.memberUrlPattern = arg.memberUrlPattern;
  }

  private processParamMatches(
    str: string,
    parentValues: { [key: string]: number }
  ): [string, string[]] {
    const colonRegex = /::([^:]+)::/g;
    let paramMatches = Array.from(str.matchAll(colonRegex));

    const unmatched: string[] = paramMatches.map((match) => {
      const param = match[1];
      const parent = this.parents.find((parent) => parent === param);
      if (parent && parentValues[parent]) {
        str = str.replace(
          new RegExp(`${match[0]}`, 'g'),
          parentValues[parent].toString()
        );
        return '';
      }
      return param;
    });
    return [str, unmatched.filter((el) => el !== '')];
  }

  getBaseUrl(
    type: 'member' | 'collection',
    parentValues: { [key: string]: number } = {}
  ) {
    //@ts-ignore
    const curlyRegex = /\{\{([^}]*)\}\}/g;
    const urlPattern =
      type === 'member' ? this.memberUrlPattern : this.collectionUrlPattern;
    const curlyStrings = urlPattern.matchAll(curlyRegex);
    let finalResult = urlPattern;

    if (!this.parents.length) return urlPattern;

    Array.from(curlyStrings).map((matchObj) => {
      const str = matchObj[0];
      const parameter = matchObj[1];
      const [result, unfulfilled] = this.processParamMatches(
        parameter,
        parentValues
      );
      finalResult =
        unfulfilled.length > 0
          ? finalResult.replace(str, '')
          : finalResult.replace(str, result);
      return null;
    });

    const [output, unfulfilled] = this.processParamMatches(
      finalResult,
      parentValues
    );
    if (unfulfilled.length > 0) {
      throw new RequiredParentMissingError(
        `Following required parents are missing ${unfulfilled.join(',')}`
      );
    }
    return output;
  }
}

export const serlializefilters = <FilterParams extends { [key: string]: any }>(
  filters: Partial<FilterParams>,
  query: string
) => {
  const keys: Array<keyof FilterParams> = Object.keys(filters).sort((a, b) =>
    a > b ? -1 : a < b ? 1 : 0
  ) as Array<keyof FilterParams>;
  let output = '';
  output = keys
    .map((key: keyof FilterParams) => {
      return filters[key] !== '' && filters[key]
        ? `filter[${key}]: ${filters[key]}\0`
        : '';
    })
    .join('');
  output += `query: ${query}\0`;
  return output;
};

/*
 Wraps all HTTP verbs.
*/
export const generateServices = <
  ResourceType,
  FilterParams extends { [name: string]: any }
>(
  resource: Resource,
  axios: AxiosInstance
) => {
  return {
    create: (
      values: ResourceType | FormData,
      parents: { [key: string]: number }
    ) => axios.post(resource.getBaseUrl('collection', parents), values),

    loadAll: (
      {
        filters,
        query,
        page,
      }: {
        query: string;
        filters: Partial<FilterParams>;
        page: number;
      },
      parents: { [key: string]: number } = {}
    ) => {
      const params = { filters, query, _page: page };
      return axios
        .get(resource.getBaseUrl('collection', parents), {
          params,
        })
        .then((response: AxiosResponse<ResourceType[]>) => response);
    },
    load: (id: number | string, parents: { [key: string]: number } = {}) =>
      axios.get(resource.getBaseUrl('member', parents) + `/${id}`),
    destroy: (id: number | string, parents: { [key: string]: number } = {}) =>
      axios.delete(resource.getBaseUrl('member', parents) + `/${id}`),
    update: (
      id: number | string,
      values: Partial<ResourceType>,
      parents: { [key: string]: number } = {}
    ) => axios.put(resource.getBaseUrl('member', parents) + `/${id}`, values),
  };
};

export interface ResourceInitialState<ResourceType, FilterParams> {
  list: { [filter: string]: { [page: number]: number[] } };
  store: { [id: number]: ResourceType };
  filters: FilterParams;
  currentPage: number;
  queryString: string;
  totalPages: { [filter: string]: number };
}

/*
  Handy helper reducers for working with lists.
  Handles pagination, filtering, reset, and fetching single entity
*/

export const generateReducers = <
  ResourceType extends { id?: number },
  FilterParams
>() => {
  return {
    clearAll: (state: ResourceInitialState<ResourceType, FilterParams>) => {
      state.list = {};
      state.currentPage = 1;
      state.totalPages = {};
    },
    loadedMany: (
      state: ResourceInitialState<ResourceType, FilterParams>,
      {
        payload,
      }: PayloadAction<{
        data: ResourceType[];
        filters: Partial<FilterParams>;
        query: string;
        page: number;
        totalPages: number;
      }>
    ) => {
      const filterString = serlializefilters<Partial<FilterParams>>(
        payload.filters,
        payload.query
      );
      state.list[filterString] = { ...state.list[filterString] } || {};
      state.totalPages[filterString] = payload.totalPages;
      state.list[filterString][payload.page] = payload.data.map((c) => {
        const id = c.id!;
        state.store[id] = c;
        return id;
      });
    },
    loadedOne: (
      state: ResourceInitialState<ResourceType, FilterParams>,
      { payload }: PayloadAction<ResourceType>
    ) => {
      const id = payload.id!;
      state.store[id] = payload;
    },
    created: (
      state: ResourceInitialState<ResourceType, FilterParams>,
      { payload }: PayloadAction<ResourceType>
    ) => {
      state.list = {};
      state.store[payload.id!] = payload;
      state.totalPages = {};
    },
    resetStore: (state: ResourceInitialState<ResourceType, FilterParams>) => {
      state.list = {};
      state.totalPages = {};
    },
    setCurrentPage: (
      state: ResourceInitialState<ResourceType, FilterParams>,
      { payload }: PayloadAction<number | string>
    ) => {
      state.currentPage = parseInt((payload || '').toString(), 10);
    },

    setQueryString: (
      state: ResourceInitialState<ResourceType, FilterParams>,
      { payload }: PayloadAction<string>
    ) => {
      if (state.queryString === payload) return;
      state.queryString = payload;

      state.currentPage = 1;
    },
    changeFilterValues: (
      state: ResourceInitialState<ResourceType, FilterParams>,
      { payload }: PayloadAction<Partial<FilterParams>>
    ) => {
      const newFilters = { ...state.filters, ...payload };
      if (state.filters !== newFilters) {
        state.currentPage = 1;
      }
      state.filters = newFilters;
    },
  };
};

export const generateSelectors = <RootState extends any>(sliceName: string) => {
  const listSelector = (state: RootState) => state[sliceName].list;
  const storeSelector = (state: RootState) => state[sliceName].store;

  const currentPageSelector = (state: RootState) =>
    state[sliceName].currentPage;

  const queryStringSelector = (state: RootState) =>
    state[sliceName].queryString;

  const totalPagesSelector = (state: RootState) => state[sliceName].totalPages;

  const filtersSelector = (state: RootState) => state[sliceName].filters;

  const listByFilterAndPageSelector = (filterString: string, page: number) => {
    return createSelector(storeSelector, listSelector, (store, list) => {
      return (list[filterString] || {})[page]?.map((id: number) => {
        return store[id];
      });
    });
  };

  const listWithResouces = createSelector(
    storeSelector,
    listSelector,
    currentPageSelector,
    queryStringSelector,
    filtersSelector,
    (store, list, currentPage) => {
      return list[currentPage]?.map((id: number) => {
        return store[id];
      });
    }
  );

  return {
    listSelector,
    storeSelector,
    currentPageSelector,
    queryStringSelector,
    totalPagesSelector,
    filtersSelector,
    listWithResouces,
    listByFilterAndPageSelector,
  };
};

export const createResourceAdapter = <
  ResourceType extends { id?: number },
  FilterParams
>(
  sliceName: string
) => {
  return {
    initialState: {
      queryString: '',
      list: {},
      currentPage: 1,
      totalPages: {},
      store: {},
      filters: {},
    },
    reducers: generateReducers<ResourceType, FilterParams>(),
    selectors: generateSelectors(sliceName),
  };
};
