import React from "react";
import CardComponent from "../../common/ui/CardComponent";
import * as slice from "./slice";
import { useSelector } from "react-redux";
import {
  Pagination,
  InputPicker,
  Panel,
  Header,
  Table,
  Whisper,
  Icon,
  Tooltip,
} from "rsuite";

import { Row, Col } from "reactstrap";
import SearchBox from "../../common/ui/SearchBox";
import { countriesSelector } from "../../app/appSlice";
import { FilterParams } from "./resource";
import ContractorsTable from "./ContractorsTable";
import { useResourceList } from "common/Resource/useResourceList";
import { Link } from "react-router-dom";
import Contractor from "../../common/interfaces/contractor";
import _ from "lodash";
import * as uuid from "uuid";

const tooltipNoAccount = <Tooltip>No Acccounts Yet.</Tooltip>;

function SelectedContractor(props: Contractor | {}) {
  return (
    <Panel header="Selected Contractor" bordered={true} shaded={true}>
      <Table
        data={Object.keys(props).length === 0 ? [] : [props]}
        renderEmpty={() => (
          <div>
            {
              "The selected contractor details will show here. Choose your contractor"
            }
          </div>
        )}
        height={100}
      >
        <Table.Column width={250} resizable={true} fixed={"left"}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Contractor) => (
              <>
                {_.isEmpty(rowData.contractor_accounts) && (
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={tooltipNoAccount}
                  >
                    <Icon
                      icon="remind"
                      style={{ marginRight: 5, color: "#ffb300" }}
                    />
                  </Whisper>
                )}
                <Link to={`/contractors/${rowData.id}`}>
                  {rowData.first_name + " " + rowData.last_name}
                </Link>
              </>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200} resizable={true}>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.Cell dataKey="email" />
        </Table.Column>
        <Table.Column width={300} resizable={true}>
          <Table.HeaderCell>Companies</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Contractor) =>
              rowData.active_contracts?.map(({ company }) => (
                <Link
                  className="p-1 badge badge-primary"
                  to={`/companies/${company!.id}`}
                  key={uuid.v4()}
                >
                  {company!.name}
                </Link>
              ))
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Country</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Contractor) => rowData.country!.name}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Panel>
  );
}

function ContractorList(props: {
  handleSelect?: (id: any) => void;
  chosen?: Contractor;
}) {
  const countries = useSelector(countriesSelector);

  const {
    list,
    filters,
    changeFilter,
    queryString,
    totalPages,
    setPage,
    setQueryString,
    currentPage,
    resetStore
  } = useResourceList<FilterParams>(slice);

  return (
    <CardComponent
      icon="users"
      header="Contractors"
      right={
        <Link className="btn btn-sm btn-primary" to="/contractors/new">
          New Contractor
        </Link>
      }
      resetStore={resetStore}
    >
      {props.handleSelect && (
        <Header className={"mb-4"}>
          <SelectedContractor {...props.chosen} />
        </Header>
      )}
      <Row className="">
        <Col xs="12" sm="5">
          <InputPicker
            value={filters.country_id_eq}
            cleanable={true}
            placeholder="Select Country"
            data={countries!.map((country) => {
              return { label: country.name, value: country.id };
            })}
            onChange={changeFilter.bind({}, "country_id_eq")}
          />
        </Col>
        <Col xs="12" sm="4" className="ml-auto">
          <SearchBox
            loading={false}
            initialValue={queryString}
            onChange={setQueryString}
          />
        </Col>
      </Row>
      <ContractorsTable
        list={list}
        handleSelect={props.handleSelect}
        chosen={props.chosen}
      />
      <div className="d-flex justify-content-center border rounded mt-2">
        <Pagination
          prev={true}
          last={true}
          next={true}
          first={true}
          ellipsis={true}
          size="lg"
          block={true}
          pages={totalPages}
          activePage={currentPage}
          boundaryLinks={true}
          className="p-1 border-rounded"
          onSelect={setPage}
        />
      </div>
    </CardComponent>
  );
}

export default ContractorList;
