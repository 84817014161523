import { useFormikContext } from 'formik';
import { Input } from 'reactstrap';
import BaseProps from './BaseProps';
import React, { ReactElement } from 'react';

interface Options {
  label: string | ReactElement,
  value: string
}
interface Props extends BaseProps {
  options?: Options[];
  placeholder: string;
}

function SelectField(props: Props) {
  const context = useFormikContext<any>();

  const { values, handleBlur, touched, errors, handleChange } = context;
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    props.onChange && props.onChange(e.target.value, context);
  };
  return (
    <Input
      type="select"
      name={props.name}
      id={props.id}
      onChange={changeHandler}
      placeholder={props.placeholder}
      readOnly={props.readonly}
      disabled={props.disabled}
      onBlur={handleBlur}
      value={values[props.name]}
      valid={touched[props.name] && !errors[props.name]}
      invalid={touched[props.name] && !!errors[props.name]}
    >
      <option value={''}>{props.placeholder}</option>
      {props.options?.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </Input>
  );
}

export default SelectField;
