import { AppDispatch } from 'app/store';
import Contract from 'common/interfaces/contract';
import AppLoader from 'common/ui/AppLoader';
import CardComponent from 'common/ui/CardComponent';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Modal } from 'rsuite';
import { loadOne, selectors, terminate } from './slice';

import moment from 'moment';
import { DatePicker } from 'rsuite';
import { AxiosError } from 'axios';
export default function ContractShow() {
  const { id } = useParams<{ id: string }>();
  const [modalVisible, setModalVisible] = useState(false);

  const contracts = useSelector(selectors.storeSelector);
  const [terminationDate, setTerminationDate] = useState<Date | undefined>(
    undefined
  );
  const [terminationError, setTerminationError] = useState<
    string | undefined
  >();
  const [loadState, setLoadState] = useState<'loading' | 'loaded' | 'errored'>(
    'loading'
  );
  const contract = contracts[id] as Contract;

  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(loadOne(id))
      .then(() => {
        setLoadState('loaded');
      })
      .catch(() => {
        setLoadState('errored');
      });
    // @ts-ignore
  }, [id, dispatch]);

  useEffect(() => {
    if (contract) setTerminationDate(new Date(contract.ends_at));
  }, [contract]);
  const terminateContract = () => {
    setTerminationError(undefined);
    dispatch(terminate(id, terminationDate!))
      .then(() => {
        setModalVisible(false);
      })
      .catch((err: AxiosError) => {
        console.log(err.response?.data);
        setTerminationError(err.response?.data.ends_at);
      });
  };
  if (loadState === 'loading' && !contract) return <AppLoader></AppLoader>;
  const Header = (
    <>
      <span className="bg-primary text-white p-2">{contract.id}</span>
      <Link className="ml-2" to={`/companies/${id}`}>
        <i className="fa fa-building p-1"></i>
        {contract.company?.name}
      </Link>
    </>
  );
  return (
    <CardComponent
      icon={'file-contract'}
      header={Header}
      right={
        <>
          <h3>
            ${contract.amount}
            <span className="badge">user{contract.frequency}</span>
          </h3>
        </>
      }
    >
      <Row>
        <Col xs={4}>
          <h4>
            <i className="fa fa-user p-2"></i>
            <Link to={`/contractors/${contract.contractor?.id}`}>
              {contract.contractor?.first_name} {contract.contractor?.last_name}
            </Link>
          </h4>
        </Col>
        <Col xs={4}>
          <h4>
            <i className="fa fa-calendar p-2"></i>
            {moment(contract.starts_at).format('D MMMM YYYY')}
            <span className="p-1">&mdash;</span>
            {contract.ends_at
              ? moment(contract.ends_at).format('D MMMM YYYY')
              : 'Ongoing'}
          </h4>
        </Col>
        <Col xs={4}>
          <Button color="warning" onClick={setModalVisible.bind({}, true)}>
            Terminate
          </Button>
          <Modal show={modalVisible} onHide={setModalVisible.bind({}, false)}>
            <Modal.Header>Terminate Contract</Modal.Header>
            <Modal.Body>
              <DatePicker
                oneTouch={true}
                onChange={setTerminationDate}
                value={terminationDate}
              />
              <div className="text-danger"> {terminationError}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                color="danger"
                disabled={!terminationDate}
                onClick={terminateContract}
              >
                Terminate
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </CardComponent>
  );
}
