import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

function AuthLinks(props: { email?: string | null }) {
  const { email } = props;
  const emailString = email ? `?email=${email}` : '';

  const router = useRouteMatch();
  console.log(router.path);
  return (
    <ul className="list-group list-group-flush mt-5">
      {router.path !== '/login' && (
        <li className="list-group-item text-center">
          <Link to={`/login${emailString}`}>Login</Link>
        </li>
      )}
      {router.path !== '/forgot' && router.path !== '/forgot_change' && (
        <li className="list-group-item text-center">
          <Link to={`/forgot${emailString}`}>Forgot Password?</Link>
        </li>
      )}
      {/*router.path !== '/signup' && (
        <li className="list-group-item text-center">
          {' '}
          <Link to={`/signup${emailString}`}>Signup</Link>
        </li>
      )*/}
    </ul>
  );
}

export default AuthLinks;
