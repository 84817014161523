import React from 'react';
import { Table } from 'rsuite';
import Payment from '../../common/interfaces/payment';
import moment from 'moment';

function PaymentsTable(props: { list: Payment[] }) {
  const { list } = props;
  return (
    <div>
      <Table loading={!list} autoHeight={true} data={list}>
        <Table.Column width={100}>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>

        <Table.Column width={200}>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.Cell>{(rowData: Payment) => rowData.company?.name}</Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.Cell>{(rowData: Payment) => rowData.amount}</Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.Cell>{(rowData: Payment) => moment(rowData.date).format('YYYY-MM-DD')}</Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Mode</Table.HeaderCell>
          <Table.Cell>{(rowData: Payment) => rowData.mode}</Table.Cell>
        </Table.Column>
        <Table.Column width={400}>
          <Table.HeaderCell>Proof of Payment</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Payment) =>
              rowData.proof_url ? (
                <img alt="Proof of Payment" src={rowData.proof_url} />
              ) : (
                <i>Not uploaded</i>
              )
            }
          </Table.Cell>
        </Table.Column>
      </Table>
    </div>
  );
}

export default PaymentsTable;
