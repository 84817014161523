import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { services } from './resource';
import CardComponent from '../../common/ui/CardComponent';
import AppLoader from '../../common/ui/AppLoader';
import { Row, Col, Table, Button } from 'reactstrap';
import { Panel, Modal, Whisper, Icon, Tooltip, Notification } from 'rsuite';
import _ from 'lodash';

import { actions } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from './slice';
import { Link } from 'react-router-dom';
import ContractList from 'features/contracts/ContractList';
import AccountForm from './AccountForm';
import AccountsTable from './AccountsTable';
import Contractor from 'common/interfaces/contractor';

const tooltipNoAccount = <Tooltip>No Acccounts Yet.</Tooltip>;

const contractorHeader = (contractor: Contractor) => (
  <>
    {contractor.first_name} {contractor.last_name}
    {_.isEmpty(contractor.contractor_accounts) && (
      <Whisper placement="top" trigger="hover" speaker={tooltipNoAccount}>
        <Icon
          icon="remind"
          style={{ marginLeft: 5, color: '#ffb300', fontSize: 18 }}
        />
      </Whisper>
    )}
  </>
);

function Show() {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  useEffect(() => {
    services.load(id).then((response) => {
      dispatch(actions.loadedOne(response.data));
    });
  }, [id, dispatch]);
  const [modal, setModal] = useState(false);
  const onBankAccountAdded = () => {
    setModal(false);
    Notification.success({ title: 'Bank account added successfully' });
  };

  const contractor = useSelector(selectors.storeSelector)[id];

  if (contractor) {
    return (
      <CardComponent
        icon="user"
        header={contractorHeader(contractor)}
        right={
          <Link to="/contractors" className="btn btn-warning btn-sm">
            Back
          </Link>
        }
      >
        <Row className="p-3">
          <Col xs={12} sm={6} md={4} className="p-2">
            <Panel header="Personal Details" shaded>
              <Table responsive>
                <tbody>
                  <tr>
                    <td>Email </td>
                    <td>{contractor.email} </td>
                  </tr>
                  <tr>
                    <td>Date of Birth </td>
                    <td>{contractor.dob} </td>
                  </tr>
                  <tr>
                    <td>Date of Birth </td>
                    <td>{contractor.dob} </td>
                  </tr>
                </tbody>
              </Table>
              <p>{contractor.city?.name}</p>
            </Panel>
          </Col>
          <Col xs={12} sm={6} md={4} className="p-2">
            <Panel header="Residential Details" shaded>
              <Table>
                <tbody>
                  <tr>
                    <td>Address </td>
                    <td className="text-break">
                      {contractor.address_street1}
                      <br />
                      {contractor.address_street2}
                    </td>
                  </tr>
                  <tr>
                    <td>City </td>
                    <td className="text-break">{contractor.city} </td>
                  </tr>
                  <tr>
                    <td>Country </td>
                    <td className="text-break">{contractor.country?.name} </td>
                  </tr>
                </tbody>
              </Table>
            </Panel>
          </Col>
          <Col xs={12} sm={6} md={4} className="p-2">
            <Panel
              header={
                <div className="d-flex align-items-center justify-content-between">
                  Accounts
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => setModal(!modal)}
                  >
                    <i className="fas fa-plus"></i> Add Account
                  </Button>
                </div>
              }
              shaded
            >
              <Modal show={modal} onHide={setModal.bind({}, !modal)}>
                <Modal.Header>
                  <Modal.Title>Add Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AccountForm
                    contractor={contractor}
                    onComplete={onBankAccountAdded}
                  />
                </Modal.Body>
              </Modal>
              <AccountsTable contractor={contractor} />
            </Panel>
          </Col>
        </Row>
        <ContractList filters={{ contractor_id_eq: id }} />
      </CardComponent>
    );
  }
  return <AppLoader />;
}

export default Show;
