import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import FormikForm, {
  FormikFailureHandler,
  TextField,
  PasswordField,
} from '../../common/FormikForm';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, updateAccount } from './authSlice';
import User from '../../common/interfaces/user';
import { FormikHelpers } from 'formik';
import { AppDispatch } from '../../app/store';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import { Notification } from 'rsuite';
function MyAccount() {
  const user = useSelector(userSelector);
  const dispatch: AppDispatch = useDispatch();
  const onSubmit = (values: User, actions: FormikHelpers<any>) => {
    dispatch(updateAccount(values))
      .then((data: User) => {
        actions.setSubmitting(false);
        Notification.success({ title: 'Account updated successfully' });
        actions.resetForm();
        actions.setValues(data);
      })
      .catch((err: AxiosError<User>) => FormikFailureHandler(err, actions));
  };
  return (
    <Card>
      <CardHeader>
        <h3>My account</h3>
      </CardHeader>
      <CardBody>
        <FormikForm onSubmit={onSubmit} initialValues={user}>
          <TextField
            label="Name"
            name="name"
            validationSchema={Yup.string().required('is required')}
          />
          <TextField
            label="Email"
            name="email"
            validationSchema={Yup.string()
              .email('must be a valid Email Address')
              .required('is required')}
          />
          <PasswordField
            label="Password"
            name="password"
            validationSchema={Yup.string()}
          />
          <PasswordField
            label="Password Confirmation"
            name="password_confirmation"
            validationSchema={Yup.string().test(
              'passwords-match',
              ' must match password',
              function (value) {
                return this.parent.password === value;
              }
            )}
          />
        </FormikForm>
      </CardBody>
    </Card>
  );
}

export default MyAccount;
