import React from 'react';
import { Popover, PopoverProps } from 'rsuite';
import { useFormikContext } from 'formik';

export default function ErrorsPopover(props: PopoverProps) {
  const { errors } = useFormikContext<any>();

  return (
    <Popover {...props}>
      <ol>
        {Object.keys(errors).map((key) => {
          return (
            <li>
              {key}: {errors[key]}
            </li>
          );
        })}
      </ol>
    </Popover>
  );
}
