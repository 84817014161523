import React from 'react';
import * as slice from './slice';
import PaymentsTable from './PaymentsTable';
import { Pagination, InputPicker } from 'rsuite';
import { Col, Row } from 'reactstrap';
import CardComponent from '../../common/ui/CardComponent';
import CompanySearchBox from '../companies/CompanySearchBox';
import { FilterParams } from './resource';
import { useResourceList } from 'common/Resource/useResourceList';

function PaymentsList(props: { filters: Partial<FilterParams> }) {
  const {
    list,
    filters,
    changeFilter,

    totalPages,
    setPage,

    currentPage,
    resetStore
  } = useResourceList<FilterParams>(slice, props.filters, !!props.filters);
  return (
    <CardComponent icon="dollar-sign" header="Payments" resetStore={resetStore}>
      <Row className="">
        <Col xs="12" sm="5"></Col>
        <Col xs="12" sm="4" className="ml-auto"></Col>
      </Row>
      {!props.filters?.company_id_eq && (
        <CompanySearchBox
          onChange={changeFilter.bind({}, 'company_id_eq')}
          value={filters['company_id_eq']}
        />
      )}
      {!props.filters?.mode_eq && (
        <InputPicker
          placeholder="Select mode of Payment"
          onChange={changeFilter.bind({}, 'mode_eq')}
          value={filters['mode_eq']}
          data={[
            { label: 'Bank Transfer', value: 'BANK_TRANSFER' },
            { label: 'Cash', value: 'CASH' },
          ]}
        />
      )}

      <PaymentsTable list={list} />
      <div className="d-flex justify-content-center border rounded mt-2">
        <Pagination
          prev
          last
          next
          first
          ellipsis
          size="lg"
          block
          pages={totalPages}
          activePage={currentPage}
          boundaryLinks
          className="p-1 border-rounded"
          onSelect={setPage}
        />
      </div>
    </CardComponent>
  );
}

export default PaymentsList;
