import Payment from '../../common/interfaces/payment';
import * as React from 'react';
import FormikForm, {
  FormikFailureHandler,
  TextField,
  DateField,
  FileField,
  SelectField,
  AmountField
} from '../../common/FormikForm';
import { AppDispatch } from '../../app/store';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormikHelpers } from 'formik';
import { dismissAppError } from '../../app/appSlice';
import { AxiosError } from 'axios';
import { createOrUpdate } from './slice';
import { Notification } from 'rsuite';
import moment from 'moment';

import * as Yup from 'yup';
function PaymentForm(props: { company_id: any }) {
  const history = useHistory();
  const initialValues = {
    date: new Date(),
    amount: '',
    mode: '',
    reference_no: '',
    proof: '',
    company_id: '',
  };

  const dispatch: AppDispatch = useDispatch();
  const handleSubmit = (values: Payment, actions: FormikHelpers<Payment>) => {
    // console.log('Values', values);
    dispatch(createOrUpdate({ ...values, company_id: props.company_id }))
      .then(() => {
        dispatch(dismissAppError());
        // dispatch
        Notification.success({
          title: `Payment created!`,
        });
        // clear field
        history.push(`/companies/${props.company_id}`);
        actions.resetForm();
      })
      .catch((err: AxiosError<any>) => {
        FormikFailureHandler(err, actions);
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <FormikForm<Payment>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      // validationSchema={paymentSchema}
    >
      <DateField
        id={'form-date'}
        name={'date'}
        label={'Date'}
        validationSchema={Yup.date()
          .nullable()
          .required('is required')
          .min(
            moment().add(-10, 'days').toDate(),
            'must be within the last 10 days'
          )}
      />
      <AmountField
        id={'form-amount'}
        name={'amount'}
        label={'Amount'}
        validationSchema={Yup.number().required().min(10).max(10000)}
      />
      <SelectField
        id={'form-mode'}
        name={'mode'}
        label={'Mode'}
        placeholder="Select mode of Payment"
        options={[
          {
            label: 'Bank Transfer',
            value: 'BANK_TRANSFER',
          },
          {
            label: 'Cash',
            value: 'CASH',
          },
        ]}
        validationSchema={Yup.string().required(' is required')}
      />
      <TextField
        id={'form-reference_no'}
        name={'reference_no'}
        label={'Reference Number'}
        validationSchema={Yup.string().required('is required')}
      />
      <FileField
        id={'form-proof'}
        name={'proof'}
        label={'Proof'}
        validationSchema={Yup.mixed().required(' is required')}
      />
    </FormikForm>
  );
}

export default PaymentForm;

// function handleSubmit(){
//   function handleCreate(values: IPayment, helpers: any) {
//     services.create(values, {})
//       .then((response)=>{
//         Notification.success({
//           title: `Payment created!`,
//         });
//         // call selctor.addOne
//         helpers.history.push('/companies');
//         // dispatch(actions.loadedOne(response.data));
//         helpers!.setSubmitting(false);
//       }).catch((e) =>  {
//       let errors = Object.keys(e.response.data);
//       Notification.error({
//         title: `${errors[0]} ${e.response.data[errors[0]][0]}`
//       })});
//   }
//   function handleUpdate(id: string, values: IPayment, helpers: any) {
//     services.update(id, values, {})
//       .then((response)=>{
//         Notification.success({
//           title: `Payment updated!`,
//         });
//         // call selctor.addOne
//
//       }).catch((e) => FormikFailureHandler(e, helpers));
//   }
// }
