import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import appReducer from './appSlice';
import authReducer from '../features/auth/authSlice';
import contractor from '../features/contractors/slice';
import company from '../features/companies/slice';
import payment from '../features/payments/slice';
import transaction from '../features/transactions/slice';
import payout from 'features/payouts/slice';
import contract from '../features/contracts/slice';
import axios from '../common/axios';
import { AxiosError } from 'axios';
export type REQUEST_ERROR_TYPES =
  | 'INTERNAL_SERVER'
  | 'BAD_REQUEST'
  | 'VALIDATION_ERROR'
  | 'LOGGED_OUT'
  | 'NETWORK_ERROR'
  | null;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const preloadedState = {
  auth: { token: localStorage.getItem('auth_token') || undefined },
};
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    app: appReducer,
    auth: authReducer,
    contractor,
    company,
    payment,
    contract,
    payout,
    transaction,
  },
  preloadedState,
  //middleware: [logger],
});

let oldToken: string | undefined = undefined;
const listener = () => {
  const state = store.getState();

  if (oldToken !== state.auth.token) {
    if (!state.auth.token) {
      // localStorage.removeItem('auth_token');
      return;
    }
    localStorage.setItem('auth_token', state.auth.token);
    oldToken = state.auth.token;
  }
};

store.subscribe(listener);

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    store.dispatch({ type: 'app/handleAppError', payload: error });
    return Promise.reject(error);
  }
);
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
