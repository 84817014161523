import React from 'react';
import * as slice from './slice';
import CompanyTable from './CompaniesTable';
import { Pagination } from 'rsuite';
import { Col, Row } from 'reactstrap';
import SearchBox from '../../common/ui/SearchBox';
import CardComponent from '../../common/ui/CardComponent';
import { Link } from 'react-router-dom';
import { useResourceList } from 'common/Resource/useResourceList';
import { FilterParams } from './resource';

function CompanyList() {
  const {
    list,
    queryString,
    totalPages,
    setPage,
    setQueryString,
    currentPage,
    resetStore
  } = useResourceList<FilterParams>(slice);
  return (
    <CardComponent
      icon="building"
      header="Companies"
      right={
        <Link className="btn btn-sm btn-primary" to="/companies/new">
          New Company
        </Link>
      }
      resetStore={resetStore}
    >
      <Row className="">
        <Col xs="12" sm="5"></Col>
        <Col xs="12" sm="4" className="ml-auto">
          <SearchBox
            loading={false}
            initialValue={queryString}
            onChange={setQueryString}
          />
        </Col>
      </Row>
      <CompanyTable list={list} />
      <div className="d-flex justify-content-center border rounded mt-2">
        <Pagination
          prev
          last
          next
          first
          ellipsis
          size="lg"
          block
          pages={totalPages}
          activePage={currentPage}
          boundaryLinks
          className="p-1 border-rounded"
          onSelect={setPage}
        />
      </div>
    </CardComponent>
  );
}

export default CompanyList;
