import React from 'react';
import * as slice from './slice';
import { FilterParams } from './resource';
import { useResourceList } from 'common/Resource/useResourceList';
import TransactionsTable from './TransactionsTable';
import { Pagination } from 'rsuite';
import CardComponent from 'common/ui/CardComponent';
export default function TransactionsList(props: {
  filters: Partial<FilterParams>;
}) {
  const {
    list,

    totalPages,
    setPage,

    currentPage,

    resetStore,
  } = useResourceList<FilterParams>(slice, props.filters, !!props.filters);
  return (
    <CardComponent
      resetStore={resetStore}
      header="Transactions"
      icon="dollar-sign"
    >
      <TransactionsTable transactions={list}></TransactionsTable>
      <div className="d-flex justify-content-center border rounded mt-2">
        <Pagination
          prev
          last
          next
          first
          ellipsis
          size="lg"
          block
          pages={totalPages}
          activePage={currentPage}
          boundaryLinks
          className="p-1 border-rounded"
          onSelect={setPage}
        />
      </div>
    </CardComponent>
  );
}
