import CardComponent from 'common/ui/CardComponent';
import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import { Row } from 'rsuite';
import { balancesSelector } from './appSlice';

export default function Dashboard() {
  const balances = useSelector(balancesSelector);
  console.log(balances);
  return (
    <div>
      <CardComponent header="Balances" icon="banknote">
        {balances.length > 0 && (
          <Row>
            {balances.map((balance) => {
              return (
                <Col className="p-3 border" xs={3}>
                  <h5>{balance.provider}</h5>
                  <h2>
                    {balance.balance} {balance.currency}
                  </h2>
                </Col>
              );
            })}
          </Row>
        )}
      </CardComponent>
    </div>
  );
}
