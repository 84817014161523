import axios from '../common/axios';
import Country from '../common/interfaces/country';
import { AxiosResponse } from 'axios';
import City from '../common/interfaces/city';
import Balance from 'common/interfaces/balance';

export const loadCountries = () => {
  return axios.get('/countries').then((response: AxiosResponse<Country[]>) => {
    return response.data;
  });
};

export const loadCities = () => {
  return axios.get('/cities').then((response: AxiosResponse<City[]>) => {
    return response.data;
  });
};

export const loadBalances = () => {
  return axios
    .get('/gateway_balances')
    .then((response: AxiosResponse<Balance[]>) => {
      return response.data;
    });
};
