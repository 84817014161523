import { AxiosResponse } from 'axios';

import Resource, { generateServices } from 'common/Resource';
import ResourceType from 'common/interfaces/payout';
import UpcomingPayout from 'common/interfaces/upcoming_payout';

import axios from 'common/axios';
import Payout from 'common/interfaces/payout';
const resource = new Resource({
  name: 'payout',
  memberUrlPattern: '/payouts',
  collectionUrlPattern: '/payouts',
  parents: [],
});

export type FilterParams = { company_id_eq: string; mode_eq: string };
const services = {
  ...generateServices<ResourceType, FilterParams>(resource, axios),
  ...{
    loadUpcomingPayouts: (params = {}) => {
      return axios
        .get('/payouts/upcoming', { params })
        .then((response: AxiosResponse<UpcomingPayout[]>) => {
          return response.data;
        });
    },
    retry: (payout_id: number) => {
      return axios
        .post(`/payouts/${payout_id}/retry`)
        .then((response: AxiosResponse<Payout>) => {
          return response.data;
        });
    },
    create_for: (date: string, company_id: number) => {
      return axios
        .post('/payouts/create_payout', { date, company_id })
        .then((response) => response.data);
    },
  },
};
export { resource, services };
