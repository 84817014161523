import { AxiosError } from 'axios';
export default function determineErrorType(e: AxiosError<any>) {
  if (e.response) {
    switch (e.response.status) {
      case 401:
        return 'LOGGED_OUT';
      case 500:
        return 'INTERNAL_SERVER';
      case 400:
        return 'BAD_REQUEST';

      case 422:
        return 'VALIDATION_ERROR';
      case 404:
        return 'NOT_FOUND';
      case 503:
        return 'GATEWEAY_TIMEOUT';
    }
  }

  return 'NETWORK_ERROR';
}
