import React from 'react';
import { Switch } from 'react-router';
import AuthenticatedRoute from '../router/AuthenticatedRoute';
import List from './CompanyList';
import Form from './Update';
import Show from './Show';
import { NewPayment } from '../payments/New';
import { v4 } from 'uuid';

function ContractorRouter() {
  return (
    <Switch>
      <AuthenticatedRoute key={v4()} path="/companies" exact component={List} />
      <AuthenticatedRoute key={v4()} path="/companies/new" exact component={Form} />
      <AuthenticatedRoute key={v4()} path="/companies/:id/edit" exact component={Form} />
      <AuthenticatedRoute key={v4()} path="/companies/:id" exact component={Show} />
      <AuthenticatedRoute key={v4()} path={"/companies/:company_id/payments/new"} exact component={NewPayment} />
    </Switch>
  );
}

export default ContractorRouter;
