import Contractor from '../../common/interfaces/contractor';
import { createSlice } from '@reduxjs/toolkit';
import { services, FilterParams } from './resource';
import { AppThunk } from '../../app/store';
import {
  ResourceInitialState,
  createResourceAdapter,
} from '../../common/Resource';
import { actions as contractActions } from '../contracts/slice';
import { AxiosResponse } from 'axios';
interface ContractorState
  extends ResourceInitialState<Contractor, FilterParams> {}

const adapter = createResourceAdapter<Contractor, FilterParams>('contractor');

const contractorSlice = createSlice({
  name: 'contractor',
  initialState: { ...adapter.initialState } as ContractorState,
  reducers: {
    ...adapter.reducers,
    madePrimary: (state, { payload }) => {
      state.store[payload.contractor_id].contractor_accounts!.forEach(
        (contractor_account, index) => {
          if (payload.prev_id === contractor_account.id) {
            state.store[payload.contractor_id].contractor_accounts![index] =
              payload.data;
          } else {
            state.store[payload.contractor_id].contractor_accounts![
              index
            ].primary = false;
          }
        }
      );
    },
    deletedAccount: (state, { payload }) => {
      state.store[payload.contractor_id].contractor_accounts = state.store[
        payload.contractor_id
      ].contractor_accounts!.filter((contractor_account) => {
        return payload.prev_id !== contractor_account.id;
      });
    },
    createdAccount: (state, { payload }) => {
      if (state.store[payload.contractor_id].contractor_accounts) {
        state.store[payload.contractor_id].contractor_accounts!.push(
          payload.data
        );
      } else {
        state.store[payload.contractor_id].contractor_accounts = [payload.data];
      }
    },
  },
});

const { loadedMany } = contractorSlice.actions;

export const { actions } = contractorSlice;
export const { selectors } = adapter;

export const loadMany = (
  page: number = 1,
  query = '',
  filters: Partial<FilterParams>
): AppThunk<any> => (dispatch) => {
  return services.loadAll({ query, filters, page }).then((response) => {
    dispatch(
      loadedMany({
        data: response.data,
        filters,
        query,
        page: parseInt(response.headers['pagination-total-pages'], 10),
        totalPages: parseInt(response.headers['pagination-total-pages'], 10),
      })
    );
  });
};

export const createOrUpdate = (
  values: Contractor,
  id?: number | string
): AppThunk<any> => (dispatch) => {
  const data: any = values;

  data.contracts_attributes = [
    {
      starts_at: new Date(values.contract_starts_at!),
      ends_at: new Date(values.contract_ends_at!),
      frequency: values.contract_frequency,
      amount: values.contract_amount!,
      company_id: values.contract_company_id,
    },
  ];

  delete data.contract_starts_at;
  delete data.contract_ends_at;
  delete data.contract_frequency;
  delete data.contract_amount;

  return (id ? services.update(id, values) : services.create(values, {})).then(
    (response: AxiosResponse<Contractor>) => {
      dispatch(actions.created(response.data));
      dispatch(contractActions.resetStore());
      return response.data;
    }
  );
};
export default contractorSlice.reducer;
