import React from 'react';
import { Switch } from 'react-router';
import AuthenticatedRoute from '../router/AuthenticatedRoute';
import ContractList from './ContractList';
import ContractForm from './ContractForm';
import ContractShow from './Show';

function ContractRouter() {
  return (
    <Switch>
      <AuthenticatedRoute path="/contracts" exact component={ContractList} />
      <AuthenticatedRoute
        path="/contracts/add"
        exact
        component={ContractForm}
      />
      <AuthenticatedRoute
        path="/contracts/:id"
        exact
        component={ContractShow}
      />
    </Switch>
  );
}

export default ContractRouter;
