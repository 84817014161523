import React from 'react';
import Contractor from '../../common/interfaces/contractor';
import { Table, Icon, Whisper, Tooltip, Checkbox } from 'rsuite';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as uuid from 'uuid';

function ContractorsTable(props: {
  list: Contractor[];
  handleSelect?: (id: any) => void;
  chosen?: Contractor;
}) {
  const { list, handleSelect } = props;
  const handleChoosing = (rowData: any) => {
    // update parent component
    return () => {
      if (handleSelect) handleSelect(rowData);
    };
  };

  const tooltipNoAccount = <Tooltip>No Acccounts Yet.</Tooltip>;

  // tslint:disable-next-line:no-console
  console.log(handleSelect);

  return (
    <div>
      <Table loading={!list} data={list}>
        {handleSelect && (
          <Table.Column width={100}>
            <Table.HeaderCell>{''}</Table.HeaderCell>
            <Table.Cell>
              {(rowData: Contractor) => (
                <Checkbox
                  checked={props.chosen && rowData.id === props.chosen.id}
                  onClick={handleChoosing(rowData)}
                />
              )}
            </Table.Cell>
          </Table.Column>
        )}
        <Table.Column width={250} resizable={true} fixed={'left'}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Contractor) => (
              <>
                {_.isEmpty(rowData.contractor_accounts) && (
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={tooltipNoAccount}
                  >
                    <Icon
                      icon="remind"
                      style={{ marginRight: 5, color: '#ffb300' }}
                    />
                  </Whisper>
                )}
                <Link to={`/contractors/${rowData.id}`}>
                  {rowData.first_name + ' ' + rowData.last_name}
                </Link>
              </>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200} resizable={true}>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.Cell dataKey="email" />
        </Table.Column>
        <Table.Column width={300} resizable={true}>
          <Table.HeaderCell>Companies</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Contractor) =>
              rowData.active_contracts?.map(({ company }) => (
                <Link
                  className="p-1 badge badge-primary"
                  to={`/companies/${company!.id}`}
                  key={uuid.v4()}
                >
                  {company!.name}
                </Link>
              ))
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Country</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Contractor) => rowData.country!.name}
          </Table.Cell>
        </Table.Column>
      </Table>
    </div>
  );
}

export default ContractorsTable;
