import { createSlice } from '@reduxjs/toolkit';
import { services, FilterParams } from './resource';
import { AppThunk } from 'app/store';
import { ResourceInitialState, createResourceAdapter } from 'common/Resource';
import Transaction from 'common/interfaces/transaction';
interface State extends ResourceInitialState<Transaction, FilterParams> {}

const adapter = createResourceAdapter<Transaction, FilterParams>('transaction');

const slice = createSlice({
  name: 'transaction',
  initialState: {
    ...adapter.initialState,
  } as State,
  reducers: adapter.reducers,
});

export const loadMany = (
  page: number = 1,
  query = '',
  filters: Partial<FilterParams>
): AppThunk<any> => (dispatch) => {
  return services.loadAll({ query, filters, page }).then((response) => {
    dispatch(
      loadedMany({
        data: response.data,
        filters,
        query,
        page: parseInt(response.headers['pagination-current-page'], 10),
        totalPages: parseInt(response.headers['pagination-total-pages'], 10),
      })
    );
  });
};
const { loadedMany } = slice.actions;

export const { actions } = slice;
export const { selectors } = adapter;

export default slice.reducer;
