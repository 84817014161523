import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import FormikForm, {
  FormikFailureHandler,
  TextField,
  EmailField,
} from 'common/FormikForm';
import { FormikHelpers } from 'formik';
import BankAccountField from 'common/interfaces/bank_account_field';
import Contractor from 'common/interfaces/contractor';
import ContractorAccount from 'common/interfaces/contractor_account';
import { Notification } from 'rsuite';
import { services } from './resource';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './slice';
import Country from 'common/interfaces/country';
import { countriesSelector } from 'app/appSlice';
const bankFields = (
  bankFields: BankAccountField[],
  contractor?: Contractor
) => {
  let elements: JSX.Element[] = [
    <TextField
      label="Bank Name"
      name="bank_name"
      validationSchema={Yup.string().required()}
    />,
    <TextField
      label="Account Holder Name"
      name="account_holder_name"
      initialValue={`${contractor?.first_name} ${contractor?.last_name}`}
      validationSchema={Yup.string().required()}
    />,
  ];
  elements = elements.concat(
    bankFields.map((field: BankAccountField) => {
      return (
        <TextField
          label={field.label}
          name={field.name}
          validationSchema={Yup.string()
            .required()
            .matches(new RegExp(field.regex), field.message || 'is invalid')}
        />
      );
    })
  );
  return elements;
};
function AccountForm(props: { contractor: Contractor; onComplete?: any }) {
  const countries = useSelector(countriesSelector);
  const [accountType] = useState('bank-transfer');
  const { contractor } = props;
  const dispatch = useDispatch();
  const [country, setCountry] = useState<Country | undefined>(undefined);
  useEffect(() => {
    const contractorCountryId = parseInt(contractor.country_id.toString(), 10);
    const countryFound = countries!.find((c) => {
      return c.id === contractorCountryId;
    });
    setCountry(countryFound);
  }, [contractor, countries]);
  const handleSubmit = (
    values: ContractorAccount,
    helpers: FormikHelpers<ContractorAccount>
  ) => {
    values.account_type = accountType;
    if (accountType === 'bank-transfer')
      values.country_id = contractor.country!.id;
    services
      .createAccount(contractor.id!, values)
      .then((response) => {
        dispatch(
          actions.createdAccount({
            contractor_id: contractor.id,
            data: response,
          })
        );
        props.onComplete && props.onComplete();
        Notification.success({ title: 'Account Added Successfully' });
        helpers.setSubmitting(false);
      })
      .catch((error: any) => {
        FormikFailureHandler(error, helpers);
      });
  };
  return (
    <div className="p-3">
      {accountType === 'bank-transfer' && country && (
        <FormikForm
          onSubmit={handleSubmit}
          children={bankFields(country.bank_account_type!.fields, contractor)}
        />
      )}
      {accountType === 'paypal' && (
        <FormikForm onSubmit={handleSubmit}>
          <EmailField
            label="Paypal Email"
            name="paypal_email"
            validationSchema={Yup.string().email().required()}
          />
        </FormikForm>
      )}
    </div>
  );
}
export default AccountForm;
