import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { userSelector } from '../auth/authSlice';
function UnauthenticatedRoute(props: {
  component?: any;

  children?: any;
  path: string;
  exact?: boolean;
}) {
  const { component, ...otherProps } = props;
  const user = useSelector(userSelector);
  console.log({ user });
  if (!user) {
    return (
      <Route {...otherProps} component={component}>
        {props.children}
      </Route>
    );
  } else {
    return <Redirect to="/" />;
  }
}
export default UnauthenticatedRoute;
