import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { services } from './resource';
import CardComponent from '../../common/ui/CardComponent';
import AppLoader from '../../common/ui/AppLoader';
import { Row, Col, Table } from 'reactstrap';
import { Panel } from 'rsuite';
import { actions } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from './slice';
import { appRoutes } from '../router/routes';
import { Link } from 'react-router-dom';
import ContractList from 'features/contracts/ContractList';
import moment from 'moment';
import TransactionsList from 'features/transactions/TransactionsList';

const formatDate = (date: string) => {
  return moment(date).format('LL');
};

function AddPayment(props: { company_id: number | string }) {
  // const { url } = useRouteMatch(); // /companies/2; ${url}/new/payment
  return (
    <Link
      to={appRoutes.company.addPayment(props.company_id)}
      className={'btn btn-sm btn-primary'}
    >
      Add Payment
    </Link>
  );
}

function AddContract(props: { company_id: number | string }) {
  // const { url } = useRouteMatch(); // /companies/2; ${url}/new/payment
  return (
    <Link
      to={appRoutes.contract.addNew(props.company_id)}
      className={'btn btn-sm btn-primary'}
    >
      Add Contract
    </Link>
  );
}

function Show() {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  useEffect(() => {
    services.load(id).then((response) => {
      dispatch(actions.loadedOne(response.data));
    });
  }, [id, dispatch]);

  const company = useSelector(selectors.storeSelector)[id];

  if (company) {
    return (
      <CardComponent
        icon="building"
        header={`${company.name}`}
        right={
          <React.Fragment>
            <AddPayment company_id={id} />
            <AddContract company_id={id} />
          </React.Fragment>
        }
      >
        <Row className="d-flex">
          <Col xs={12} sm={6} lg={3} className="mb-4">
            <Panel className="card-balance" bordered header="Balance">
              <span className="ammount">${company.balance}</span>
            </Panel>
          </Col>
          <Col xs={12} sm={6} lg={4} className="mb-4">
            <Panel bordered header="Address">
              <p>{company.address}</p>
              <p className="mt-0">{company.city?.name}</p>
            </Panel>
          </Col>
          <Col xs={12} sm={6} lg={5} className="mb-4">
            <Panel header="Highlights" bordered>
              <Table>
                <tbody>
                  <tr>
                    <td>Created </td>
                    <td>{formatDate(company.created_at)} </td>
                  </tr>
                  <tr>
                    <td>Active Contracts </td>
                    <td>{company.contracts_count} </td>
                  </tr>
                </tbody>
              </Table>
            </Panel>
          </Col>
        </Row>
        <ContractList filters={{ company_id_eq: id }} />
        <TransactionsList filters={{ company_id: id }} />
      </CardComponent>
    );
  }
  return <AppLoader />;
}

export default Show;
