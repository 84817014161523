import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './store';
import * as services from './services';
import Country from '../common/interfaces/country';
import City from '../common/interfaces/city';
import { AxiosError } from 'axios';
import determineErrorType from '../common/determineErrorType';
import Balance from 'common/interfaces/balance';
interface AppState {
  countries?: Country[];
  countriesloaded: boolean;
  cities?: City[];
  citiesLoaded: boolean;
  balances: { [account: string]: Balance };
  errorInProgress: ReturnType<typeof determineErrorType> | null;
}
const initialState: AppState = {
  countriesloaded: false,
  citiesLoaded: false,
  errorInProgress: null,
  balances: {},
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    countriesLoaded: (state, { payload }: PayloadAction<Country[]>) => {
      state.countries = payload;
      state.countriesloaded = true;
    },
    citiesLoaded: (state, { payload }: PayloadAction<City[]>) => {
      state.cities = payload;
      state.citiesLoaded = true;
    },
    dismissAppError: (state) => {
      state.errorInProgress = null;
    },
    handleAppError: (state, { payload }: PayloadAction<AxiosError<any>>) => {
      state.errorInProgress = determineErrorType(payload);
    },
    balancesLoaded: (state, { payload }: PayloadAction<Balance[]>) => {
      payload.map((balance) => {
        return (state.balances[balance.name] = balance);
      });
    },
  },
});
export const {
  balancesLoaded,
  handleAppError,
  countriesLoaded,
  citiesLoaded,
  dismissAppError,
} = appSlice.actions;

export const loadCountries = (): AppThunk => (dispatch) => {
  return services.loadCountries().then((countries: Country[]) => {
    return dispatch(countriesLoaded(countries));
  });
};

export const loadCities = (): AppThunk => (dispatch) => {
  return services.loadCities().then((cities: City[]) => {
    return dispatch(citiesLoaded(cities));
  });
};

export const loadBalances = (): AppThunk => (dispatch) => {
  return services.loadBalances().then((balances) => {
    return dispatch(balancesLoaded(balances));
  });
};
export const countriesSelector = (state: RootState) => state.app.countries;
export const citiesSelector = (state: RootState) => state.app.cities;

export const balancesSelector = (state: RootState) =>
  Object.values(state.app.balances).sort((a: Balance, b: Balance) =>
    a.name.localeCompare(b.name)
  );
export const appLoaded = (state: RootState) => {
  return state.auth.metaLoaded && state.app.countriesloaded;
};
export const appErrorSelector = (state: RootState) => {
  return state.app.errorInProgress;
};
export default appSlice.reducer;
