import Resource, { generateServices } from '../../common/Resource';
import Contract from '../../common/interfaces/contract';

import axios from '../../common/axios';
import { AxiosResponse } from 'axios';
import moment from 'moment';
const resource = new Resource({
  name: 'contract',
  memberUrlPattern: '/contracts',
  collectionUrlPattern: '/contracts',
  parents: [],
});

export type FilterParams = { company_id_eq: string; contractor_id_eq: string };
const services = {
  ...generateServices<Contract, FilterParams>(resource, axios),
  terminate: (id: number | string, date: Date) =>
    axios
      .put(`/contracts/${id}/terminate`, {
        ends_at: moment(date).format('YYYY-MM-DD'),
      })
      .then((response: AxiosResponse<Contract>) => response.data),
};
export { resource, services };
