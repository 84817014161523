import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { services, FilterParams } from './resource';
import { AppThunk, RootState } from '../../app/store';
import {
  ResourceInitialState,
  createResourceAdapter,
} from '../../common/Resource';
import Contract from '../../common/interfaces/contract';
import { AxiosResponse } from 'axios';
interface State extends ResourceInitialState<Contract, FilterParams> {
  terminating: { [id: number]: boolean };
}

const adapter = createResourceAdapter<Contract, FilterParams>('contract');

const slice = createSlice({
  name: 'contract',
  initialState: { ...adapter.initialState, terminating: {} } as State,
  reducers: {
    ...adapter.reducers,
    terminating: (
      state,
      { payload }: PayloadAction<{ id: number; status: boolean }>
    ) => {
      state.terminating[payload.id] = payload.status;
    },
  },
});

const { loadedMany, loadedOne } = slice.actions;

export const { actions } = slice;
export const { selectors } = adapter;

export const loadMany = (
  page: number = 1,
  query = '',
  filters: Partial<FilterParams>
): AppThunk<any> => (dispatch) => {
  return services.loadAll({ query, filters, page }).then((response) => {
    dispatch(
      loadedMany({
        data: response.data,
        filters,
        query,
        page: parseInt(response.headers['pagination-current-page'], 10),
        totalPages: parseInt(response.headers['pagination-total-pages'], 10),
      })
    );
  });
};
export const loadOne = (id: number | string): AppThunk<any> => (dispatch) => {
  return services.load(id).then((response) => {
    dispatch(loadedOne(response.data));
  });
};

export const createOrUpdate = (
  values: Contract,
  id?: number | string
): AppThunk<any> => (dispatch) => {
  return (id ? services.update(id, values) : services.create(values, {})).then(
    (response: AxiosResponse<Contract>) => {
      dispatch(actions.created(response.data));
      dispatch(actions.resetStore());
      return response.data;
    }
  );
};

export const terminate = (id: number | string, date: Date): AppThunk<any> => (
  dispatch
) => {
  dispatch(actions.terminating({ id: +id, status: true }));

  return services.terminate(id, date).then((contract: Contract) => {
    dispatch(actions.loadedOne(contract));
    dispatch(actions.terminating({ id: +id, status: false }));

    return contract;
  });
};

export const terminatingSelector = (state: RootState) =>
  state.contract.terminating;
export default slice.reducer;
