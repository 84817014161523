import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'reactstrap';
import { NavLink as NavLinkRRD } from 'react-router-dom';

function Navlist(props: {
  routes: { label: string; icon: string; path: string }[];
  visible?: string[];
}) {
  return (
    <Nav navbar>
      {props.routes.map((route) => {
        return (
          <NavItem key={route.path}>
            <NavLink tag={NavLinkRRD} exact to={`/${route.path}`}>
              <i className={`fa fa-${route.icon} p-1"`} />
              <span className="d-none d-md-inline">{route.label}</span>
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
}

export default Navlist;
