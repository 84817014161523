import Resource, { generateServices } from '../../common/Resource';
import ResourceType from '../../common/interfaces/payment';

import axios from '../../common/axios';
const resource = new Resource({
  name: 'payment',
  memberUrlPattern: '/payments',
  collectionUrlPattern: '/payments',
  parents: [],
});

export type FilterParams = { company_id_eq: string; mode_eq: string };
const services = {
  ...generateServices<ResourceType, FilterParams>(resource, axios),
};
export { resource, services };
