import React from 'react';
import Contract from '../../common/interfaces/contract';
import { Table } from 'rsuite';
import { Link } from 'react-router-dom';

function ContractsTable(props: { contracts: Contract[] }) {
  const { contracts } = props;

  return (
    <div>
      <Table loading={!contracts} autoHeight={true} data={contracts}>
        <Table.Column width={200}>
          <Table.HeaderCell>Contractor</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Contract) => (
              <Link to={`/contractors/${rowData.contractor_id}`}>
                {rowData.contractor!.first_name} {rowData.contractor!.last_name}
              </Link>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.Cell>
            {(rowData: Contract) => (
              <Link to={`/companies/${rowData.company_id}`}>
                {rowData.company!.name}
              </Link>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.Cell dataKey="amount" />
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
          <Table.Cell dataKey="starts_at" />
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>End Date</Table.HeaderCell>
          <Table.Cell dataKey="ends_at" />
        </Table.Column>

        <Table.Column width={200}>
          <Table.HeaderCell>Actions</Table.HeaderCell>
          <Table.Cell>
            {(row: Contract) => {
              return (
                <Link
                  className="btn btn-primary btn-sm"
                  to={`/contracts/${row.id}`}
                >
                  <i className="fa fa-eye" />
                  View
                </Link>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </div>
  );
}

export default ContractsTable;
