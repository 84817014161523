import axios from '../../common/axios';
import { AxiosResponse } from 'axios';
import User from '../../common/interfaces/user';
import LoginRequest from '../../common/interfaces/loginRequest';
import LoginResponse from '../../common/interfaces/loginResponse';
export const signup = (user: User) => {
  return axios
    .post('/signup', { user })
    .then((response: AxiosResponse<{ user: User }>) => {
      return response.data;
    });
};

export interface FindAccountResponse {
  signed_up: boolean;
  pending_confirmation?: boolean;
}
export const findAccount = (email: string) => {
  return axios
    .get('/find_account', { params: { email } })
    .then((response: AxiosResponse<FindAccountResponse>) => {
      return response.data;
    });
};

export const resendConfirmationEmail = (email: string) => {
  return axios
    .post('/resend_confirmation_mail', { email })
    .then((response: AxiosResponse<FindAccountResponse>) => {
      return response.data;
    });
};

export const confirmAccount = (email: string, token: string) => {
  return axios
    .post('/confirm', { email, token })
    .then((response: AxiosResponse<FindAccountResponse>) => {
      return response.data;
    });
};

export const forgotPassword = (email: string) => {
  return axios
    .post('/forgot_password', { email })
    .then((response: AxiosResponse<any>) => {
      return response.data;
    });
};

export const verify_forgot_token = (email: string, token: string) => {
  return axios
    .post('/verify_forgot_token', { email, token })
    .then((response: AxiosResponse<any>) => {
      return response.data;
    });
};

export const forgot_password_change = (
  email: string,
  token: string,
  password: string
) => {
  return axios
    .post('/forgot_password_change', { email, token, password })
    .then((response: AxiosResponse<any>) => {
      return response.data;
    });
};
export const login = ({ email, password }: LoginRequest) => {
  return axios
    .post('/login', { email, password })
    .then((response: AxiosResponse<LoginResponse>) => {
      return response.data;
    });
};

export const meta = () => {
  return axios.get('/meta').then((response: AxiosResponse<LoginResponse>) => {
    return response.data;
  });
};

export const updateAccount = (user: User) => {
  return axios.put('/update_account', { user });
};
