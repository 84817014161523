import Resource, { generateServices } from 'common/Resource';
import ResourceType from 'common/interfaces/transaction';

import axios from 'common/axios';
export const resource = new Resource({
  name: 'transaction',
  memberUrlPattern: '/transactions',
  collectionUrlPattern: '/transactions',
  parents: [],
});

export type FilterParams = {
  company_id: string;
  entity_type: string;
  direction: string;
};
export const services = {
  ...generateServices<ResourceType, FilterParams>(resource, axios),
};
