import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  InputGroupText,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { AppDispatch } from '../../app/store';

import { Fade } from 'react-awesome-reveal';
import { AxiosError } from 'axios';
import { forgotPassword } from './authSlice';
import AuthLinks from './AuthLinks';
function ForgotPassword() {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const [email, setEmail] = useState<string>('');
  const [status, setStatus] = useState<string>('ready');
  const emailFromParams = params.get('email');
  useEffect(() => {
    if (emailFromParams) {
      setEmail(emailFromParams);
    }
  }, [emailFromParams]);

  const dispatch: AppDispatch = useDispatch();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('submitting');
    dispatch(forgotPassword(email))
      .then(() => {
        setStatus('success');
      })
      .catch((err: AxiosError<any>) => {
        setStatus('failed');
      });
  };
  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };
  return (
    <div className="main-content bg-default">
      <div className="header bg-gradient-primary py-7 py-lg-8">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col lg="5" md="6">
                <h1 className="text-white">Forgot Password</h1>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
      {/* Page content */}

      <Container className="mt--8 pb-5 w-25 ">
        <Card className="bg-secondary shadow border-0 ">
          <CardBody>
            {(status === 'ready' || status === 'submitting') && (
              <Fade>
                <Form className="mt-5" onSubmit={handleSubmit}>
                  <p className="text-center mb-2">
                    Enter your email address below to continue
                  </p>

                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-envelope" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="email"
                      onChange={handleEmailChange}
                      placeholder={'email@gmail.com'}
                      value={email}
                    />
                    <InputGroupAddon addonType="append">
                      <Button color="primary" disabled={!email} type="submit">
                        Continue
                        {status === 'submitting' && (
                          <i className="fa fa-spin fa-spinner" />
                        )}
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Form>
              </Fade>
            )}
            {status === 'success' && (
              <Fade>
                <div className="p-3">
                  <h2 className="text-center mb-3">
                    <i className="fa fa-envelope-open-text text-success fa-4x" />
                  </h2>
                  Your request to reset password was accepted. If you have an
                  account with us, we'll send you an email with further
                  information. Please check your spam folder as well for our
                  email.
                </div>
              </Fade>
            )}
            {status === 'failed' && (
              <Fade>
                <div className="p-3">
                  <h2 className="text-center mb-3">
                    <i className="fa fa-exclamation-triangle text-danger fa-4x" />
                  </h2>
                  Something went wrong with processing this request. Try again
                  in a few minutes, or raise this issue with support
                </div>
              </Fade>
            )}
            <AuthLinks email={email} />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default ForgotPassword;
