import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Table } from 'rsuite';

import Payout from 'common/interfaces/payout';
import { useDispatch, useSelector } from 'react-redux';
import { retryPayout, retryingPayoutsSelector } from './slice';

function PayoutsTable(props: { payouts: Payout[] }) {
  const dispatch = useDispatch();

  const handleRetry = (payout_id: number) => {
    dispatch(retryPayout(payout_id));
  };
  const retryingPayouts = useSelector(retryingPayoutsSelector);

  const { payouts } = props;
  return (
    <Table
      className="table"
      data={payouts}
      autoHeight
      minHeight={200}
      rowClassName={(rowData: Payout) =>
        rowData && rowData.status === 'failed' ? 'bg-warning' : ''
      }
    >
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Payout Id</Table.HeaderCell>
        <Table.Cell>
          {(rowData: Payout) => (
            <Link to={`/payouts/${rowData.id}`}>{rowData.id}</Link>
          )}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.Cell>
          {(rowData: Payout) => moment(rowData.date).format('DD MMMM YYYY')}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Amount</Table.HeaderCell>
        <Table.Cell dataKey="amount" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Company</Table.HeaderCell>
        <Table.Cell>
          {(rowData: Payout) => (
            <Link to={`/companies/${rowData.company!.id}`}>
              {rowData.company!.name}
            </Link>
          )}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.Cell>
          {(rowData: Payout) => <div>{rowData.status}</div>}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Failed</Table.HeaderCell>
        <Table.Cell>
          {(rowData: Payout) =>
            `${rowData.child_counts.failed} / ${rowData.child_counts.total}`
          }
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={2}>
        <Table.HeaderCell>Actions</Table.HeaderCell>
        <Table.Cell>
          {(rowData: Payout) =>
            (rowData.status === 'failed' || rowData.status === 'cancelled') && (
              <Button
                size="sm"
                color="primary"
                onClick={handleRetry.bind({}, rowData.id)}
                disabled={retryingPayouts[rowData.id]}
              >
                <i className=" mr-2 fa fa-redo-alt"></i>
                Retry
              </Button>
            )
          }
        </Table.Cell>
      </Table.Column>
    </Table>
  );
}

export default PayoutsTable;
