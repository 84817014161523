import { generateSelectors, serlializefilters } from '.';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AppThunk } from 'app/store';
type ActionsParamType<FilterParams> = {
  setCurrentPage: ActionCreatorWithPayload<string | number, string>;
  changeFilterValues: ActionCreatorWithPayload<Partial<FilterParams>, string>;
  setQueryString: ActionCreatorWithPayload<string, string>;
  resetStore: any;
};
type SliceType<FilterParams> = {
  actions: ActionsParamType<FilterParams>;
  loadMany: (
    page: number,
    query: string,
    filters: Partial<FilterParams>
  ) => AppThunk<any>;
  selectors: ReturnType<typeof generateSelectors>;
};
export const useResourceList = <FilterParams extends { [name: string]: any }>(
  slice: SliceType<FilterParams>,
  fixedFilterValues: Partial<FilterParams> = {},
  paginateInState: boolean = false
) => {
  const dispatch = useDispatch();
  const {
    listByFilterAndPageSelector,
    currentPageSelector,
    queryStringSelector,
    filtersSelector,
    totalPagesSelector,
  } = slice.selectors;
  const {
    setCurrentPage,
    changeFilterValues,
    setQueryString: setQueryStringInStore,
    resetStore: reset,
  } = slice.actions;
  const [currentPageInState, setCurrentPageInState] = useState<number>(1);
  const currentPageInStore = useSelector(currentPageSelector);
  const currentPage = paginateInState ? currentPageInState : currentPageInStore;
  const queryStringInStore = useSelector(queryStringSelector);
  const [queryStringInState, setQueryStringInState] = useState<string>('');
  const queryString = paginateInState ? queryStringInState : queryStringInStore;
  const filtersInStore = useSelector(filtersSelector);
  const [filtersInState, setFiltersInState] = useState<Partial<FilterParams>>(
    {}
  );

  const filters = paginateInState
    ? { ...filtersInState, ...fixedFilterValues }
    : filtersInStore;

  const filterString = serlializefilters(filters, queryString);
  const totalPages = useSelector(totalPagesSelector)[filterString];
  const list = useSelector(
    listByFilterAndPageSelector(filterString, currentPage)
  );

  const setPage = (page: number) => {
    paginateInState
      ? setCurrentPageInState(page)
      : dispatch(setCurrentPage(page));
  };

  const changeFilter = (name: keyof FilterParams, value: string) => {
    const filterModified = { [name]: value };

    if (paginateInState) setFiltersInState({ ...filters, ...filterModified });
    //@ts-ignore
    else dispatch(changeFilterValues(filterModified));
  };
  const setQueryString = (query: string) => {
    if (paginateInState) setQueryStringInState(query);
    else dispatch(setQueryStringInStore(query));
  };

  const resetStore = () => dispatch(reset());

  useEffect(() => {
    if (!list) dispatch(slice.loadMany(currentPage, queryString, filters));
  }, [queryString, currentPage, filters, dispatch, list, slice]);
  return {
    totalPages,
    currentPage,
    filters,
    list,
    setPage,
    changeFilter,
    queryString,
    setQueryString,
    resetStore,
  };
};
