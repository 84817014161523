import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InputPickerField from '../../common/FormikForm/InputPickerField';
import * as Yup from 'yup';
import FormikForm, {
  DateField,
  ContractorSelectField,
  CompanyField,
  FormikFailureHandler,
  AmountField,
} from '../../common/FormikForm';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as companySelectors, loadOne } from '../companies/slice';
import { FormikHelpers } from 'formik';
import Contract from '../../common/interfaces/contract';
import { createOrUpdate } from './slice';
import { Notification } from 'rsuite';
import { AxiosError } from 'axios';
import { AppDispatch } from '../../app/store';
import CardComponent from 'common/ui/CardComponent';

// Consider putting this in common
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ContractForm(props: any) {
  const dispatch: AppDispatch = useDispatch();

  const history = useHistory();
  const [loadingError, setLoadingError] = useState(false);
  const handleSubmit = (values: Contract, helpers: FormikHelpers<Contract>) => {
    helpers.setSubmitting(true);

    dispatch(createOrUpdate(values))
      .then(() => {
        Notification.success({
          title: `Contract created!`,
        });
        helpers.setSubmitting(false);
        history.push(`/companies/${values.company_id}`);
      })
      .catch((e: AxiosError) => FormikFailureHandler(e, helpers));
  };

  const companyId = useQuery().get('companyId');
  const companies = useSelector(companySelectors.storeSelector);
  const company = companyId ? companies[companyId] : null;
  useEffect(() => {
    if (companyId)
      dispatch(loadOne(companyId)).catch((err: Error) => {
        setLoadingError(true);
      });
  }, [companyId, dispatch]);

  if (loadingError)
    return (
      <>
        The company you requested couldn't be loaded. The link maybe invalid or
        the server is down.
      </>
    );
  if (!company) return <>Currently only accessible through company page</>;

  return (
    <CardComponent icon={'file-contract'} header={'Add Contract'}>
      <FormikForm onSubmit={handleSubmit} initialValues={{}}>
        <AmountField name={'amount'} label={'Amount'} />
        <CompanyField
          value={companyId ? companyId : ''}
          name={'company_id'}
          label={'Company'}
          title={company?.name}
          validationSchema={Yup.string().required('is required')}
        />
        <ContractorSelectField
          label={'Contractor'}
          name={'contractor_id'}
          validationSchema={Yup.string().required('is required')}
        />
        <DateField
          label="Start Date"
          name="starts_at"
          // @ts-ignore
          oneTap={true}
          initialValue={moment().toDate()}
          validationSchema={Yup.date().required('is required')}
        />
        <DateField
          label="End Date"
          name="ends_At"
          //@ts-ignore
          oneTap={true}
          validationSchema={Yup.date().min(Yup.ref('starts_at'))}
        />

        <InputPickerField
          label="Frequency"
          name="frequency"
          placeholder="Select Frequency"
          options={[
            { value: 'bimonthly', label: 'Bi-Monthly' },
            { value: 'monthly', label: 'Monthly' },
          ]}
          validationSchema={Yup.string().required('is required')}
        />
      </FormikForm>
    </CardComponent>
  );
}
