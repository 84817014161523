import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appErrorSelector, dismissAppError } from './appSlice';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { logout } from 'features/auth/authSlice';

function ErrorHandler() {
  const error = useSelector(appErrorSelector);
  let errorTitle = '';
  let errorBody = <></>;
  const dispatch = useDispatch();
  const doLogout = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(logout());
  };
  const dismissError = () => {
    dispatch(dismissAppError());
  };
  if (!error) return null;
  if (error) {
    switch (error) {
      case 'NOT_FOUND':
        errorTitle = 'Resource Not Found';
        errorBody = (
          <>
            The resource you requested was not found. It may have been deleted
            or the link might be incorrect
          </>
        );
        break;
      case 'INTERNAL_SERVER':
        errorTitle = 'Server Error';
        errorBody = (
          <>
            The server returned an error on the last action. Please inform
            development team
          </>
        );
        break;
      case 'LOGGED_OUT':
        errorTitle = 'Logged Out';
        errorBody = (
          <>
            You have been logged out. Please{' '}
            <Link to="/login" onClick={doLogout}>
              {' '}
              log in
            </Link>{' '}
            again.
          </>
        );
        break;

      case 'NETWORK_ERROR':
        errorTitle = 'Connectivity Issue';
        errorBody = (
          <>
            We're unable to establish a connection to the server. Please check
            your internet connection.{' '}
          </>
        );
        break;
      case 'BAD_REQUEST':
        errorTitle = 'Bad Request';
        errorBody = (
          <>
            The request was not understood by the server. Please try again or
            inform devleopment team
          </>
        );
        break;
    }
  }
  if (!errorTitle) return null;

  return (
    <Card className="bg-danger text-white">
      <CardHeader className="bg-danger text-white">
        <div className="float-left">
          <h3>
            <i className="fa fa-exclamation-circle p-1" />
            {errorTitle}
          </h3>
        </div>
        <div className="float-right">
          <Button size="sm" color="primary" onClick={dismissError}>
            X Dismiss
          </Button>
        </div>
      </CardHeader>
      <CardBody>{errorBody}</CardBody>
    </Card>
  );
}

export default ErrorHandler;
