import InputFieldBaseProps from "./BaseProps";
import React from "react";
import { useFormikContext } from "formik";

interface Props extends InputFieldBaseProps {
  value: string;
  title: string;
}

export default function CompanyField(props: Props) {
  const context = useFormikContext<any>();
  const { setFieldValue } = context;
  React.useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setFieldValue(props.name, props.value);
    }
    return () => {
      ignore = true;
    };
  }, [setFieldValue, props]);

  return (
    <div className={"rs-picker-input p-2 companyField"}>{props.title}</div>
  );
}
