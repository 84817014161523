import React from 'react';
import CardComponent from '../../common/ui/CardComponent';
import ContractsTable from './ContractsTable';
import * as slice from './slice';
import { Pagination } from 'rsuite';
import CompanySearchBox from 'features/companies/CompanySearchBox';
import { FilterParams } from './resource';
import { useResourceList } from 'common/Resource/useResourceList';

function ContractList(props: { filters?: Partial<FilterParams> }) {
  const {
    list,
    filters,
    changeFilter,
    totalPages,
    setPage,
    currentPage,
    resetStore
  } = useResourceList<FilterParams>(slice, props.filters, !!props.filters);
  return (
    <CardComponent icon="file-contract" header="Contracts" resetStore={resetStore}>
      {!props.filters?.company_id_eq && (
        <CompanySearchBox
          onChange={changeFilter.bind({}, 'company_id_eq')}
          value={filters['company_id_eq']}
        />
      )}
      <ContractsTable contracts={list} />
      <div className="d-flex justify-content-center border rounded mt-2">
        <Pagination
          prev
          last
          next
          first
          ellipsis
          size="lg"
          block
          pages={totalPages}
          activePage={currentPage}
          boundaryLinks
          className="p-1 border-rounded"
          onSelect={setPage}
        />
      </div>
    </CardComponent>
  );
}

export default ContractList;
