import React from 'react';
import { Table, Whisper, Popover } from 'rsuite';

import PayoutContract from 'common/interfaces/payout_contract';

function PayoutContractsTable(props: { payoutsContracts: PayoutContract[] }) {
  const { payoutsContracts } = props;

  return (
    <Table className="table" bordered hover data={payoutsContracts}>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.Cell dataKey="id" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Contractor</Table.HeaderCell>
        <Table.Cell>
          {(rowData: PayoutContract) =>
            `${rowData.contract!.contractor!.first_name} ${
              rowData.contract!.contractor!.last_name
            }`
          }
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Amount</Table.HeaderCell>
        <Table.Cell dataKey="amount" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.Cell>
          {(rowData: PayoutContract) => (
            <>
              {rowData.status}
              {(rowData.status === 'failed' ||
                rowData.status === 'cancelled') && (
                <Whisper
                  trigger="hover"
                  speaker={
                    <Popover title="Error">
                      <p>{rowData.error}</p>
                    </Popover>
                  }
                >
                  <span style={{ marginLeft: 5 }}>
                    <i className="fa fa-exclamation-triangle " />
                  </span>
                </Whisper>
              )}
            </>
          )}
        </Table.Cell>
      </Table.Column>
    </Table>
  );
}

export default PayoutContractsTable;
