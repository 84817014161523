import React from 'react';
import { Switch } from 'react-router';
import AuthenticatedRoute from '../router/AuthenticatedRoute';
import List from './PaymentList';


function PaymentRouter() {
  return (
    <Switch>
      <AuthenticatedRoute path="/payments" exact component={List} />
    </Switch>
  );
}

export default PaymentRouter;
