import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import { FormikHelpers } from 'formik';
import LoginRequest from '../../common/interfaces/loginRequest';
import { login } from './authSlice';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AppDispatch } from '../../app/store';

import * as Yup from 'yup';
import { AxiosError } from 'axios';
import AuthLinks from './AuthLinks';
import FormikForm, {
  FormikFailureHandler,
  TextField,
  PasswordField,
} from '../../common/FormikForm';
import { dismissAppError } from '../../app/appSlice';
function Login() {
  const history = useHistory();
  const location = useLocation();
  let initialEmail = undefined;

  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  if (email) {
    initialEmail = email;
  }

  const dispatch: AppDispatch = useDispatch();
  const handleSubmit = (
    values: LoginRequest,
    actions: FormikHelpers<LoginRequest>
  ) => {
    dispatch(login(values))
      .then(() => {
        dispatch(dismissAppError());
        history.push('/');
      })
      .catch((err: AxiosError<any>) => {
        FormikFailureHandler(err, actions);
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };
  return (
    <div className="main-content bg-default">
      <div className="header bg-gradient-primary py-7 py-lg-8">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col lg="5" md="6">
                <h1 className="text-white">Login</h1>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
      {/* Page content */}

      <Container className="mt--8 pb-5 w-25">
        <Card className="bg-secondary shadow border-0 ">
          <CardBody>
            <FormikForm<LoginRequest> onSubmit={handleSubmit}>
              <TextField
                initialValue={initialEmail}
                name="email"
                label="Email"
                validationSchema={Yup.string()
                  .email('must be a valid Email Address')
                  .required('must not be empty')
                  .nullable()}
              />
              <PasswordField
                name="password"
                label="Password"
                validationSchema={Yup.string().required('must not be empty')}
              />
            </FormikForm>
            <AuthLinks email={email} />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default Login;
