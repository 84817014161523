import { createSlice } from '@reduxjs/toolkit';
import { services, FilterParams } from './resource';
import { AppThunk, RootState } from 'app/store';
import { ResourceInitialState, createResourceAdapter } from 'common/Resource';
import Payout from 'common/interfaces/payout';
interface State extends ResourceInitialState<Payout, FilterParams> {
  upcomingPayouts: [];
  retryingPayout: { [value: number]: boolean };
}

const adapter = createResourceAdapter<Payout, FilterParams>('payout');

const slice = createSlice({
  name: 'payout',
  initialState: {
    ...adapter.initialState,
    retryingPayout: {},
  } as State,
  reducers: {
    ...adapter.reducers,
    loadedUpcomingPayouts: (state, { payload }) => {
      state.upcomingPayouts = payload.data;
    },

    retryingPayout: (
      state,
      { payload: { id, status } }: { payload: { id: number; status: boolean } }
    ) => {
      state.retryingPayout[id] = status;
    },

    retriedPayout: (state, { payload }) => {
      state.retryingPayout[payload] = true;

      if (payload.prev_id === payload.data.id) {
        state.store[payload.prev_id] = payload.data;
      } else {
        delete state.store[payload.prev_id];
        state.store[payload.data.id] = payload.data;
        let list_key: string;
        for (list_key in state.list) {
          for (let page_key in state.list[list_key]) {
            let page_contents = state.list[list_key][page_key];
            page_contents.splice(
              page_contents.findIndex((content) => content === payload.prev_id),
              1,
              payload.data.id
            );
          }
        }
      }
    },
  },
});

const {
  loadedMany,
  loadedUpcomingPayouts,
  retriedPayout,
  retryingPayout,
} = slice.actions;

export const { actions } = slice;
export const { selectors } = adapter;

export const loadMany = (
  page: number = 1,
  query = '',
  filters: Partial<FilterParams>
): AppThunk<any> => (dispatch) => {
  return services.loadAll({ query, filters, page }).then((response) => {
    dispatch(
      loadedMany({
        data: response.data,
        filters,
        query,
        page: parseInt(response.headers['pagination-current-page'], 10),
        totalPages: parseInt(response.headers['pagination-total-pages'], 10),
      })
    );
  });
};

export const loadUpcomingPayouts = (): AppThunk<any> => (dispatch) => {
  return services.loadUpcomingPayouts().then((response) => {
    dispatch(loadedUpcomingPayouts({ data: response }));
  });
};

export const retryPayout = (payout_id: number): AppThunk<any> => (dispatch) => {
  dispatch(retryingPayout({ id: payout_id, status: true }));
  return services
    .retry(payout_id)
    .then((response) => {
      dispatch(retryingPayout({ id: payout_id, status: false }));

      dispatch(
        retriedPayout({
          prev_id: payout_id,
          data: response,
        })
      );
    })
    .catch(() => {
      dispatch(retryingPayout({ id: payout_id, status: false }));
    });
};

export const createPayout = (
  date: string,
  company_id: number
): AppThunk<any> => (dispatch) => {
  return services.create_for(date, company_id).then((response) => {
    dispatch(loadUpcomingPayouts());
    return response;
  });
};

export const upcomingPayoutsSelector = (state: RootState) => {
  return state.payout.upcomingPayouts;
};

export const retryingPayoutsSelector = (state: RootState) => {
  return state.payout.retryingPayout;
};
export default slice.reducer;
