import React, { ReactElement, ReactNode, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Button, Icon, FlexboxGrid, IconButton } from 'rsuite';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';

function CardComponent(props: {
  header: string | ReactElement;
  icon: string;
  children?: ReactNode;
  right?: ReactElement;
  collapsible?: boolean;
  isCollapsed?: boolean;
  resetStore?: () => void;
}) {
  const { isCollapsed } = props;
  const [collapsed, setCollapsed] = useState<boolean>(false);
  useEffect(() => {
    if (isCollapsed !== undefined) setCollapsed(isCollapsed);
  }, [isCollapsed]);
  return (
    <Card>
      <CardHeader>
        <h4 className="float-left">
          {props.icon && <i className={`fa fa-${props.icon} pr-2`} />}
          {props.header}
          {props.resetStore && (<Button className="ml-2" onClick={props.resetStore}>
          <Icon icon="refresh" />
        </Button>)}
        </h4>

        <FlexboxGrid justify="space-between" className="float-right">
          <FlexboxGridItem>{props.right}</FlexboxGridItem>
          <FlexboxGridItem className="ml-2">
            {props.collapsible && (
              <IconButton
                icon={
                  <i className={`fa fa-${collapsed ? 'plus' : 'minus'} `} />
                }
                circle
                color="blue"
                onClick={setCollapsed.bind({}, !collapsed)}
              />
            )}
          </FlexboxGridItem>
        </FlexboxGrid>
      </CardHeader>

      {props.children && (!props.collapsible || !collapsed) && (
        <CardBody>{props.children}</CardBody>
      )}
    </Card>
  );
}

export default CardComponent;
