import React from 'react';
import { Pagination } from 'rsuite';

import * as slice from './slice';
import CardComponent from 'common/ui/CardComponent';
import PayoutsTable from './PayoutsTable';
import { useResourceList } from 'common/Resource/useResourceList';
import { FilterParams } from './resource';

function PayoutList(props: { filters: Partial<FilterParams> }) {
  const {
    list,

    totalPages,
    setPage,

    currentPage,

    resetStore,
  } = useResourceList<FilterParams>(slice, props.filters, !!props.filters);
  return (
    <CardComponent icon="money-bill" header="Payouts" resetStore={resetStore}>
      <PayoutsTable payouts={list} />

      <div className="d-flex justify-content-center border rounded mt-2">
        <Pagination
          prev
          last
          next
          first
          ellipsis
          size="lg"
          block
          pages={totalPages}
          activePage={currentPage}
          boundaryLinks
          className="p-1 border-rounded"
          onSelect={setPage}
        />
      </div>
    </CardComponent>
  );
}

export default PayoutList;
