import Resource, { generateServices } from 'common/Resource';
import Contractor from 'common/interfaces/contractor';
import { AxiosResponse } from 'axios'

import axios from 'common/axios';
import ContractorAccount from 'common/interfaces/contractor_account';
const resource = new Resource({
  name: 'contractor',
  memberUrlPattern: '/contractors',
  collectionUrlPattern: '/contractors',
  parents: [],
});

export type FilterParams = { country_id_eq: string };
const services = {
  ...generateServices<Contractor, FilterParams>(resource, axios),
  ...{
    createAccount: (contractorId: number | string, account: ContractorAccount) => {
      return axios
        .post(`/contractors/${contractorId}/contractor_accounts`, account)
        .then((response: AxiosResponse<ContractorAccount>) => {
          return response.data
        })
    },
    makeAccountPrimary: (id: number | string) => {
      return axios
        .put(`/contractor_accounts/${id}/make_primary`)
        .then((response: AxiosResponse<ContractorAccount>) => {
          return response.data
        })
    },
    deleteAccount: (id: number | string) => {
      return axios
        .delete(`/contractor_accounts/${id}`)
        .then((response: AxiosResponse<ContractorAccount>) => {
          return response.data
        })
    },
  }
};
export { resource, services };
