import { useFormikContext } from 'formik';
import { Input } from 'reactstrap';
import BaseProps from './BaseProps';

import React from 'react';
interface Props extends BaseProps {}

function FileField(props: Props) {
  const context = useFormikContext<any>();
  const { handleBlur, touched, errors, setFieldValue } = context;
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.files ? e.target.files[0] : null;
    setFieldValue(props.name, value, true);
    props.onChange && props.onChange(value, context);
  };
  return (
    <Input
      type="file"
      name={props.name}
      id={props.id}
      className={'form-control'}
      onChange={changeHandler}
      readOnly={props.readonly}
      disabled={props.disabled}
      onBlur={handleBlur}
      valid={touched[props.name] && !errors[props.name]}
      invalid={touched[props.name] && !!errors[props.name]}
    />
  );
}

FileField.defaultInitialValue = '';
export default FileField;
