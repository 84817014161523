import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';

function SearchBox(props: {
  initialValue: string;
  loading: boolean;
  onChange: (value: string) => void;
}) {
  const { initialValue, loading } = props;

  const [query, setQuery] = useState('');
  useEffect(() => {
    setQuery(initialValue);
  }, [initialValue]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };
  const clearQueryString = () => {
    setQuery('');
    props.onChange('');
  };
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.onChange(query);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Input
          onChange={handleChange}
          value={query}
          type="text"
          placeholder="Search by first name, last name, email"
        />
        <InputGroupAddon addonType="append">
          <Button
            type="submit"
            color="primary"
            disabled={query === initialValue || loading}
          >
            {loading ? (
              <i className="fa fa-spin fa-spinner" />
            ) : (
              <i className="fa fa-search" />
            )}
          </Button>
          {query && (
            <Button
              type="submit"
              color="muted"
              onClick={clearQueryString}
              disabled={query === ''}
            >
              <i className="fa fa-times" />
            </Button>
          )}
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
}

export default SearchBox;
