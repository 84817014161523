import axios from 'axios';
import config from '../config';
import qs from 'qs';
const baseURL = config.apiUrl;
const instance = axios.create({
  baseURL,
  paramsSerializer: (params: any) => {
    return qs.stringify(params);
  },
});
instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('auth_token')}`;

  config.headers.Accept = 'application/json';
  return config;
});
export default instance;
