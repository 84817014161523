import { createSlice } from '@reduxjs/toolkit';
import { services, FilterParams } from './resource';
import { AppThunk } from '../../app/store';
import {
  ResourceInitialState,
  createResourceAdapter,
} from '../../common/Resource';
import Company from '../../common/interfaces/company';
import { AxiosResponse } from 'axios';

interface State extends ResourceInitialState<Company, FilterParams> {}

const adapter = createResourceAdapter<Company, FilterParams>('company');

let sliceName = 'company';

const slice = createSlice({
  name: sliceName,
  initialState: { ...adapter.initialState } as State,
  reducers: { ...adapter.reducers },
});

const { loadedMany, loadedOne } = slice.actions;

export const { actions } = slice;

// Extend default Selectors
export const selectors = {
  ...adapter.selectors,
  // Since useSelector is passed state only, one needs to curry the selector.
  // usage: useSelector(selecactionstCompany(state)(id))
  selectCompany: function (state: any) {
    return function (id: any) {
      return state[sliceName].store[id];
    };
  },
};

// Makes the call to services and calls loadedMany Action created above.
export const loadMany = (
  page: number = 1,
  query = '',
  filters: Partial<FilterParams>
): AppThunk<any> => (dispatch) => {
  return services.loadAll({ query, filters, page }).then((response) => {
    dispatch(
      loadedMany({
        data: response.data,
        filters,
        query,
        totalPages: parseInt(response.headers['pagination-total-pages'], 10),
        page: parseInt(response.headers['pagination-current-page'], 10),
      })
    );
  });
};

export const loadOne = (id: number | string): AppThunk<any> => (dispatch) => {
  return services.load(id).then((response) => {
    dispatch(loadedOne(response.data));
  });
};

export const createOrUpdate = (
  values: Company,
  id?: number | string
): AppThunk<any> => (dispatch) => {
  return (id ? services.update(id, values) : services.create(values, {})).then(
    (response: AxiosResponse<Company>) => {
      dispatch(actions.created(response.data));
      return response.data;
    }
  );
};

export default slice.reducer;
