import React, { ReactElement } from 'react';
// @ts-nocheck jsx-no-lambda

import { Form, FormGroup, Button, Col, UncontrolledAlert } from 'reactstrap';

import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import BaseProps from './BaseProps';
import { Whisper } from 'rsuite';
import ErrorsPopover from './ErrorsPopover';

export default function FormikForm<T>(props: {
  children: ReactElement<BaseProps>[] | ReactElement<BaseProps>;
  onSubmit?: (values: T, actions: FormikHelpers<T>) => void;
  initialValues?: any;
  validationSchema?: any;
  inline?: boolean;
  submitLabel?: ReactElement | string;
  submitClass?: string;
}) {
  const { children, submitLabel = 'Submit' } = props;
  const fields: ReactElement<BaseProps>[] = Array.isArray(children)
    ? children
    : [children];
  //Collects validationSchema from each field
  const validationSchemaFromFields = fields.reduce(
    (hash: { [key: string]: any }, field) => {
      if (field.props.validationSchema)
        hash[field.props.name] = field.props.validationSchema;
      return hash;
    },
    {}
  );

  const initialValuesFromFields = fields.reduce((hash: any, field) => {
    let initialValue = field.props.initialValue || '';

    hash[field.props.name] = initialValue;
    return hash;
  }, {});
  console.log({
    schema: {
      ...validationSchemaFromFields,
      ...props.validationSchema,
    },
  });
  const derivedValidationSchema = Yup.object().shape({
    ...validationSchemaFromFields,
    ...props.validationSchema,
  });
  const derivedInitialValues = {
    ...initialValuesFromFields,
    ...props.initialValues,
  };

  const submitHandler = (values: T, actions: FormikHelpers<T>) => {
    props.onSubmit && props.onSubmit(values, actions);
  };
  const popover = <ErrorsPopover />;
  return (
    <Formik
      onSubmit={submitHandler}
      initialValues={derivedInitialValues}
      validationSchema={derivedValidationSchema}
    >
      {(formikProps: FormikProps<any>) => {
        const {
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          status,
          submitCount,

          isValid,
        } = formikProps;
        return (
          <Form onSubmit={handleSubmit}>
            {status && (
              <UncontrolledAlert fade={false} color="danger">
                {status}
              </UncontrolledAlert>
            )}
            {fields.map((field) => {
              return (
                <FormGroup row={true} key={field.props.name}>
                  <Col sm={4} xs={12}>
                    <label htmlFor={`form-${field.props.name}`}>
                      {field.props.label}
                    </label>
                  </Col>

                  <Col sm={8} xs={12}>
                    {field}

                    <small className={`text-danger`}>
                      {errors[field.props.name] &&
                        touched[field.props.name] &&
                        `${field.props.label}  ${errors[field.props.name]}`}
                    </small>
                  </Col>
                </FormGroup>
              );
            })}
            <Button
              type="submit"
              color="primary"
              className={`mt-4 ${props.submitClass || ''}`}
            >
              {submitLabel}

              {isSubmitting && <i className="fa fa-spin fa-spinner" />}
            </Button>
            {!isValid && submitCount > 0 && (
              <Whisper trigger="click" placement="top" speaker={popover}>
                <i className="fa fa-2x fa-exclamation-circle" />
              </Whisper>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export function FormikFailureHandler<ResponseType>(
  error: AxiosError<ResponseType>,
  { setErrors, setStatus, setSubmitting }: FormikHelpers<ResponseType>
) {
  setSubmitting(false);
  if (error.response) {
    if (error.response.status === 422) {
      const count = Object.keys(error.response.data).length;

      setStatus(
        `${count} Validation Errors prevented the form from submitting`
      );
      setErrors(error.response.data);
    }
    if (error.response.data) {
      //@ts-ignore
      const message = error.response.data.message;
      if (message) {
        setStatus(message);
      }
    }
  } else {
    setStatus(error.message);
  }
}

export { default as DateField } from './DateField';
export { default as FileField } from './FileField';
export { default as PasswordField } from './PasswordField';
export { default as SelectField } from './SelectField';
export { default as TextAreaField } from './TextAreaField';
export { default as TextField } from './TextField';
export { default as EmailField } from './EmailField';
export { default as AmountField } from './AmountField';
export { default as ContractorSelectField } from './ContractorSelectField';
export { default as CompanyField } from './CompanyField';
export type FormikFormBaseProps = BaseProps;
